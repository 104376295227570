






















































import ImageUploader from "@/components/Molecules/ImageUploader/index.vue";
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";
import { defineComponent } from "vue";
import ProtocolConfirmationModal from "@/components/Organisms/ProtocolConfirmationModal.vue";

export default defineComponent({
  components: {
    ImageUploader,
    FixedButtonRow,
    ProtocolConfirmationModal
  },
  data() {
    return {
      confirmation: false
    };
  },
  computed: {
    requestAndAuthorization() {
      switch (this.$route.name) {
        case "analise_cobertura.pedido_e_autorizacao":
          return true;
        default:
          return false;
      }
    },
    title() {
      if (this.requestAndAuthorization) {
        return "Envio de pedido médico para análise de coberturas e autorização médica";
      } else {
        return "Envio de pedido médico para análise de coberturas";
      }
    },
    description() {
      if (this.requestAndAuthorization) {
        return "Envie o pedido médico com a descrição dos exames para analisarmos as coberturas e a autorização médica.";
      } else {
        return "Nos envie o pedido médico com a descrição dos exames para analisarmos as coberturas.";
      }
    },
    confirmationText() {
      if (this.requestAndAuthorization) {
        return "Documentos enviados para análise de cobertura";
      } else {
        return "Exames enviados para análise de cobertura";
      }
    }
  }
});
