<template>
  <OmtLayoutContainer>
    <OmtRow mb="24px">
      <LineStepper :currentStep="currentStep" :totalSteps="totalSteps" />
    </OmtRow>
    <template v-if="currentStep === 1">
      <OmtRow>
        <ExamesSearch
          @search="handleSearch"
          title="Buscar exames"
          text="Serão exibidos os exames e seus <b>detalhes</b> em relação a cobertura."
          placeholder="Busque pelo nome do exame"
        />
      </OmtRow>
      <OmtRow v-for="(exam, index) in filteredExams" :key="index">
        <OmtCard mb="16px" p="12px 16px">
          <CFlex>
            <CoverageAnalysesResult :name="exam.name" :result="exam.state" />
            <OmtIcons :name="hasItem(exam) ? 'minus' : 'plus'" size="lg" @click="selectedItem(exam, index)" />
          </CFlex>
        </OmtCard>
      </OmtRow>
      <FixedButtonRow>
        <ButtonCounter
          :w="['100%', '308px !important']"
          :count="seletectExames.length"
          :disabled="!seletectExames.length"
          @click="nextStep()"
          label="Ver exames"
          fontSize="20px"
          fontWeight="700"
        />
      </FixedButtonRow>
    </template>
    <template v-if="currentStep === 2">
      <OmtRow mb="8px">
        <OmtTypography as="h1-bold" lineHeight="32px">Exames selecionados</OmtTypography>
      </OmtRow>
      <OmtRow mb="16px">
        <CFlex justify="space-between">
          <OmtTypography as="p2" color="var(--colors-light-primary-gray2)"
            >{{ seletectExames.length }} exame(s) selecionado(s)</OmtTypography
          >
          <CBox @click="clearSelectItems()">
            <OmtTypography as="button-small" color="var(--colors-light-primary-navy-blue)"> Limpar tudo </OmtTypography>
          </CBox>
        </CFlex>
      </OmtRow>
      <OmtRow v-for="(exam, index) in seletectExames" :key="index">
        <OmtCard mb="16px">
          <CFlex>
            <CoverageAnalysesResult :name="exam.name" :result="exam.state" />
            <OmtIcons :name="hasItem(exam) ? 'minus' : 'plus'" size="lg" @click="selectedItem(exam, index)" />
          </CFlex>
        </OmtCard>
      </OmtRow>
      <FixedButtonRow :useFooter="false">
        <CFlex direction="column" :w="['100%', '308px !important']" align="center">
          <OmtTypography as="button-medium" color="var(--colors-light-primary-navy-blue)" mb="24px">
            Salvar e enviar documentos
          </OmtTypography>
          <ButtonCounter
            :w="['100%', '308px !important']"
            :count="seletectExames.length"
            :disabled="!seletectExames.length"
            label="Salvar"
            fontSize="20px"
            fontWeight="700"
          />
        </CFlex>
      </FixedButtonRow>
    </template>
  </OmtLayoutContainer>
</template>

<script>
import ExamesSearch from "@/components/Organisms/ExamesSearch/ExamesSearch.vue";
import CoverageAnalysesResult from "@/components/Molecules/CoverageAnalysesResult/index.vue";
import ButtonCounter from "@/components/Molecules/ButtonCounter";
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";
import LineStepper from "@/components/Atoms/LineStepper/index.vue";
const exams = [
  {
    id: 1,
    name: "Sumário de Urina",
    state: "coverage"
  },

  {
    id: 2,
    name: "Hemograma",
    state: "request"
  },
  {
    id: 3,
    name: "Tomografia de crânio",
    state: "authorization"
  }
];
export default {
  components: {
    CoverageAnalysesResult,
    FixedButtonRow,
    ButtonCounter,
    ExamesSearch,
    LineStepper
  },
  data() {
    return {
      exams: exams,
      seletectExames: [],
      currentStep: 1,
      totalSteps: 2,
      searchQuery: ""
    };
  },
  computed: {
    filteredExams() {
      return this.exams.filter(exam => exam.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
    }
  },
  watch: {
    seletectExames(newValue) {
      if (newValue.length === 0) {
        this.currentStep = 1;
        this.searchQuery = "";
      }
    }
  },
  methods: {
    selectedItem(item) {
      const existingItem = this.seletectExames.find(exam => exam.id === item.id);
      if (!existingItem) {
        this.seletectExames.push(item);
      } else {
        this.seletectExames = this.seletectExames.filter(exam => exam.id !== item.id);
      }
    },
    hasItem(item) {
      return this.seletectExames.some(exam => exam.id === item.id);
    },
    nextStep() {
      this.currentStep = 2;
    },
    clearSelectItems() {
      this.seletectExames = [];
      this.currentStep = 1;
    },
    handleSearch(value) {
      this.searchQuery = value;
    }
  }
};
</script>
