import { render, staticRenderFns } from "./index.vue?vue&type=template&id=7d99c898&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&scopped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CAccordionIcon: require('@chakra-ui/vue').CAccordionIcon, CAccordionHeader: require('@chakra-ui/vue').CAccordionHeader, CAccordionPanel: require('@chakra-ui/vue').CAccordionPanel, CAccordionItem: require('@chakra-ui/vue').CAccordionItem})
