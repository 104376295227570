<template>
  <omint-page-content v-if="task" class="outer">
    <resouce-modal v-if="shouldRequestResources" />
    <div class="middle">
      <div v-if="erroAoRecuperarDados" class="pre-call-box">
        <div class="cam error">
          <i class="far fa-frown"></i>
        </div>
        <h2>Ops! Algo deu errado</h2>
        <p>Aguarde, em alguns minutos entraremos em contato.</p>
      </div>
      <div v-else class="pre-call-box">
        <div class="cam">
          <i class="fas fa-video"></i>
        </div>
        <h2 v-if="waiting > 0">
          Chamando
          <div class="dot dot-show">.</div>
          <div :class="waiting > 1 ? 'dot' : 'dot dot-hidden'">.</div>
          <div :class="waiting > 2 ? 'dot' : 'dot dot-hidden'">.</div>
        </h2>
        <img class="avatar" :src="imagemProfile" v-if="imagemProfile !== ''" />
        <div v-else class="avatar">
          <center>
            <i class="fa fa-user fa-5x"></i>
          </center>
        </div>
        <h3>{{ pessoa }}</h3>
        <h4>{{ perfilPessoa }}</h4>
        <p>Estamos conectando...</p>
      </div>
    </div>
  </omint-page-content>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ResouceModal from "@/components/Organisms/DrOmint/ResourceModal";
import { importScript } from "@/utils/scriptUtil";

export default {
  name: "TaskPreCall",
  components: {
    ResouceModal
  },
  data() {
    return {
      initialized: false,
      task: null,
      waiting: 0,
      waitingTimer: null,
      checkPeopleTimer: null,
      checkingResources: true,
      cameraLiberada: false,
      microfoneLiberado: false,
      tentativas: 0,
      pessoa: "",
      perfilPessoa: "",
      imagemProfile: "",
      recuperandoDados: false,
      erroAoRecuperarDados: false
    };
  },
  computed: {
    ...mapState("saude", ["perfil"]),
    ...mapState("tasks", ["callInfos"]),
    ...mapState("biometrics", ["camera", "microfone"]),
    ...mapGetters("biometrics", ["onNative"]),
    ...mapGetters("saude", ["defaultRoute"]),
    apiKey() {
      return process.env.VUE_APP_OPENTOK_APIKEY;
    },
    apiJS() {
      return process.env.VUE_APP_BASE_URL + "scripts/opentok-client.2.28.6.min.js";
    },
    v25() {
      return Number.parseInt(localStorage.getItem("native_version")) >= 25;
    },
    shouldRequestResources() {
      if (!this.v25 || !this.onNative)
        return !this.checkingResources && (!this.cameraLiberada || !this.microfoneLiberado);
      return this.v25 && this.onNative && (this.camera !== "granted" || this.microfone !== "granted");
    },
    tempoWaitingEfect() {
      return 1000; // 1 segundo para andar os dots
    },
    tempoTentativas() {
      return 1000; // a cada  segundo verifica se a outra pessoa entrou na sala
    },
    limiteTentativas() {
      return 1200; // tentar no máximo por 20 minutos
    },
    chamado() {
      return this.$route.params.chamado ? Number(this.$route.params.chamado) : 0;
    }
  },
  methods: {
    ...mapActions("myAccount", ["registerAccess"]),
    ...mapActions("tasks", [
      "featchTaskDetail",
      "featchVideoCallTokens",
      "featchOnlinePeople",
      "featchImagemProfile",
      "registerRoomAccess"
    ]),
    ...mapActions("biometrics", ["checkPermissionForResource"]),
    async initResources() {
      if (this.initialized) return;
      if (this.onNative) {
        this.checkPermissionForResource("camera");
        this.checkPermissionForResource("microfone");
        this.checkingResources = false;
      } else {
        await this.checkResources();
      }
      await importScript(document, this.apiJS);
      this.initialized = true;
    },
    async checkResources() {
      if (navigator && "mediaDevices" in navigator && "getUserMedia" in navigator.mediaDevices) {
        try {
          const audioStream = await navigator.mediaDevices.getUserMedia({
            audio: true
          });
          const tracks = audioStream.getTracks();
          for (let i = 0; i < tracks.length; i++) {
            await tracks[i].stop();
          }
          this.microfoneLiberado = true;
        } catch (error) {
          this.microfoneLiberado = false;
          process.browser && window.$log.error("Error on checkResources audio", error);
        }

        try {
          const videoStream = await navigator.mediaDevices.getUserMedia({
            video: true
          });
          const tracks = videoStream.getTracks();
          for (let i = 0; i < tracks.length; i++) {
            await tracks[i].stop();
          }
          this.cameraLiberada = true;
        } catch (error) {
          this.cameraLiberada = false;
          process.browser && window.$log.error("Error on checkResources video", error);
        }
      } else {
        this.cameraLiberada = false;
        this.microfoneLiberado = false;
      }
      this.checkingResources = false;
    },
    aguardando() {
      this.waiting = this.waiting === 3 ? 1 : this.waiting + 1;
    },
    cancelarTimers() {
      if (this.waitingTimer !== null) {
        clearInterval(this.waitingTimer);
      }
      if (this.checkPeopleTimer !== null) {
        clearInterval(this.checkPeopleTimer);
      }
    },
    async iniciarRegras() {
      this.pessoa = this.task.Staff;
      this.perfilPessoa = this.task.Especialidade;
      this.featchImagemProfile({
        staff: this.task.LinkImagemProfile
      }).then(r => (this.imagemProfile = r));
    },
    async verificarPessoasOnline() {
      if (this.recuperandoDados || this.erroAoRecuperarDados || this.shouldRequestResources || this.checkingResources)
        return;

      try {
        this.recuperandoDados = true;
        const salaDisponivel = await this.verificarSala();
        if (salaDisponivel) {
          this.registrarAcesso();
        }
      } finally {
        this.recuperandoDados = false;
      }
    },
    async registrarAcesso() {
      // o associado só registra acesso após a staff
      const pessoasOnline = await this.featchOnlinePeople({
        chamado: this.task.Chamado
      });
      if (pessoasOnline?.DataHoraStaffOnline) {
        this.registerRoomAccess({ tpPessoa: this.task.TpPessoa });
        this.verificarAcessos();
      }
      if (this.tentativas >= this.limiteTentativas) {
        this.erroAoRecuperarDados = true;
      } else {
        this.tentativas++;
      }
    },
    verificarAcessos() {
      this.cancelarTimers();
      Swal.fire({
        text: "O profissional já entrou na consulta, clique em iniciar.",
        confirmButtonText: "Iniciar",
        allowOutsideClick: false
      }).then(async () => {
        this.$router.push({
          name: "tasks.call",
          query: {
            chamado: this.task.Chamado
          }
        });
      });
    },
    async verificarSala() {
      const salaDisponivel = await this.featchVideoCallTokens({
        chamado: this.task.Chamado,
        tpPessoa: this.task.TpPessoa
      });
      if (salaDisponivel) {
        return true;
      }
      if (this.tentativas >= this.limiteTentativas) {
        this.erroAoRecuperarDados = true;
      } else {
        this.tentativas++;
      }
      return false;
    }
  },
  beforeDestroy() {
    this.cancelarTimers();
  },
  async created() {
    await this.initResources();
  },
  async mounted() {
    try {
      if (this.chamado === 0) {
        return;
      }
      this.registerAccess({ servico: "Videoconferência", funcionalidade: "Acesso a Sala de Espera", id: this.chamado });
      this.task = await this.featchTaskDetail({ chamado: this.chamado });
      if (!this.task) {
        return;
      }
      // caso a url do opentok seja usada com uma task do teams
      if (this.task.UrlSala) {
        return this.$router.replace({
          name: "tasks.video-chamada",
          params: {
            chamado: this.task.Chamado
          }
        });
      }
      this.iniciarRegras();
      this.verificarPessoasOnline();
      this.waitingTimer = setInterval(this.aguardando, this.tempoWaitingEfect);
      this.checkPeopleTimer = setInterval(this.verificarPessoasOnline, this.tempoTentativas);
    } catch (error) {
      this.erroAoRecuperarDados = true;
      window.$log.error("Erro ao carregar a tarefa", error);
    } finally {
      if (this.task) {
        await this.$root.$emit("hide-loading");
      } else {
        this.$router.push(this.defaultRoute);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.outer {
  display: table;
  width: calc(100% - 50px);
}

.middle {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}

.pre-call-box {
  margin: auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  & .cam {
    margin-bottom: 20px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    text-align: center;
    & i {
      margin-top: 12px;
      margin-left: 1px;
      font-size: 24px !important;
    }
  }
  & h2 {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    font-weight: 600;
    line-height: 21px;
    margin-top: 20px;
    & {
      .dot {
        width: 10px;
      }
      & .dot-hidden {
        color: transparent;
      }
    }
  }
  & h3 {
    margin: 0px;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
  }
  & h4 {
    margin: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
  & p {
    margin-top: 20px;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
  }
  & .avatar {
    margin: 30px;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    text-align: center;
    & i {
      font-size: 68px !important;
      margin-top: 20px;
    }
  }
}
.big-font-size {
  & h2 {
    font-size: 25px;
  }
  & h3 {
    font-size: 20px;
  }
  & h4 {
    font-size: 18px;
  }
  & p {
    font-size: 15px;
  }
}
.dark {
  .pre-call-box {
    & .error {
      background-color: white !important;
      color: black;
    }
    & .cam,
    .avatar {
      background-color: black;
    }
  }
}

.light {
  .pre-call-box {
    h3 {
      color: var(--colors-light-primary-gray1);
    }
    & .error {
      background-color: #68788c !important;
      color: white;
    }
    & .cam,
    .avatar {
      background-color: white;
    }
  }
}
.task-container {
  margin: 16px;

  .cam-container-img {
    background: #ffffff;
    padding: 3px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
  }

  .subtitle {
    font-size: 20px;
    font-weight: 600;
    line-height: 21px;
    margin-bottom: 16px;
    margin-top: 16px;
    color: var(--colors-light-primary-gray1);
  }

  .doctor-name {
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    margin: 0px;
    margin-top: 16px;
    color: var(--colors-light-primary-gray1);
  }

  .doctor-speciality {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin: 0px;
    margin-bottom: 16px;
    color: var(--colors-light-primary-gray1);

    .small {
      font-size: 12px;
      line-height: 17px;
    }
  }
}
</style>
