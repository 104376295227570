























































import { defineComponent, ref } from "vue";
import OmtTextSignature from "@/components/Atoms/omt-text-signature.vue";
import HealthProgramsSwiper from "@/components/Organisms/HealthPrograms/HealthProgramsSwiper.vue";
import ProtocolConfirmationModal from "@/components/Organisms/ProtocolConfirmationModal.vue";

const coverImage = process.env.VUE_APP_BASE_URL + "images/health_programs/cover.png";

export default defineComponent({
  components: {
    OmtTextSignature,
    HealthProgramsSwiper,
    ProtocolConfirmationModal
  },
  setup() {
    const confirmation = ref(false);
    return {
      coverImage,
      confirmation
    };
  }
});
