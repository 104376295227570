<template>
  <OmtLayoutHeaderFooter :footer="false" :bg="['none', '#F6F6F9']" :header="{ titleLabel: 'Inadimplência' }">
    <OmtLayoutContainer>
      <OmtRow mb="8px">
        <OmtTypography as="h1-bold" lineHeight="32px"> O pagamento da sua fatura está pendente </OmtTypography>
      </OmtRow>
      <OmtRow flexDirection="column">
        <OmtTypography as="p1" lineHeight="22px" mb="8px">
          Olá, <omt-bold>{{ debtData.Nome }}! </omt-bold><br />
        </OmtTypography>
        <OmtTypography as="p1" lineHeight="22px">
          Não identificamos o pagamento da fatura do seu plano de saúde individual
          <omt-bold>{{ debtData.Plano }}</omt-bold
          >, com vencimento em <omt-bold>{{ debtData.Vencimento }}</omt-bold
          >, no valor de
          <omt-bold>{{ debtData.Valor }}.</omt-bold>
        </OmtTypography>
      </OmtRow>
      <OmtRow>
        <omt-divider />
      </OmtRow>
      <OmtRow>
        <CBox v-if="debtData.Beneficiarios">
          <OmtTypography as="h2-bold" mb="16px"> Beneficiários</OmtTypography>
          <CList styleType="decimal" v-for="(beneficiario, index) of debtData.Beneficiarios" :key="beneficiario.CPF">
            <CListItem display="flex" :mb="index != debtData.Beneficiarios.length - 1 ? '8px' : ''">
              <OmtTypography as="p1" lineHeight="22px">
                {{ index + 1 }}. {{ beneficiario.Nome }} - CPF: {{ beneficiario.CPF }}
              </OmtTypography>
            </CListItem>
          </CList>
        </CBox>
      </OmtRow>
      <OmtRow>
        <omt-divider />
      </OmtRow>
      <OmtRow mb="16px" flexDirection="column">
        <OmtTypography as="p1" lineHeight="22px" mb="8px">
          Regularize o pagamento e evite a suspensão do seu contrato.
        </OmtTypography>
        <OmtTypography as="p1" lineHeight="22px" mb="8px">
          Em caso de dúvidas entre em contato pelos números (11) 4004 4011 na Grande São Paulo ou 0800 726 4000 para
          Demais Regiões.
        </OmtTypography>
        <OmtTypography as="p1" lineHeight="22px">
          Caso o pagamento já tenha sido realizado, por favor, nos envie o comprovante pelo e-mail: xxxxxx@omint.com.br
          .
        </OmtTypography>
      </OmtRow>
      <OmtRow mb="42px" v-if="debtData.Faturas">
        <OmtCard p="18px 16px 21px" v-for="fatura of debtData.Faturas" :key="fatura.Fatura">
          <CFlex mb="24px" align-items="start">
            <OmtIcons name="memo" size="1x" mr="10px" />
            <OmtTypography as="h3-bold"> {{ fatura.Data }} </OmtTypography>
            <OmtIcons name="angle-right" ml="auto" size="1x" />
          </CFlex>
          <CFlex align-items="center">
            <OmtButton
              variant="outline"
              height="40px"
              width="122px"
              py="10px"
              px="39px"
              border="0.5px solid var(--colors-light-secondary-divider)"
              @click="download(fatura.url)"
            >
              <OmtTypography as="h3-semi-bold"> Baixar </OmtTypography>
            </OmtButton>
          </CFlex>
        </OmtCard>
      </OmtRow>

      <FixedButtonRow :useFooter="false">
        <OmtButton :w="['100%', '308px !important']" @click="next()"> Continuar </OmtButton>
      </FixedButtonRow>
    </OmtLayoutContainer>
  </OmtLayoutHeaderFooter>
</template>
<script>
import Header from "@/components/Molecules/Header";
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";

export default {
  name: "notPay",
  components: {
    Header,
    FixedButtonRow
  },
  computed: {
    ...mapState("accessManagement", ["debtData"]),
    ...mapState("saude", ["vinculo"])
  },
  methods: {
    ...mapActions("accessManagement", ["registerDebtNaturalPerson"]),
    async download(url) {
      if (!url) return;
      window.open(url, "_blank");
    },
    async next() {
      if (!this.debtData?.Faturas || this.debtData?.Faturas?.length === 0) {
        return;
      }

      this.$root.$emit("show-loading");
      const result = await this.registerDebtNaturalPerson({ titulo: this.debtData.Faturas[0].Fatura });
      if (result !== "") {
        this.$router.replace("/");
      } else {
        Swal.fire({
          icon: "error",
          text: "Serviço temporariamente indisponível",
          confirmButtonText: "OK"
        });
        this.$root.$emit("hide-loading");
      }
    }
  },
  async mounted() {
    if (!this.debtData?.Faturas) {
      this.$router.replace("/");
    }

    this.$root.$emit("hide-back-button");
    try {
      this.$root.$emit("show-loading");
    } catch (error) {
      console.error("not pay resume load error", error);
    } finally {
      this.loading = false;
      this.$root.$emit("hide-loading");
    }
  }
};
</script>
