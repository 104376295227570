







import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    iconName: {
      required: false,
      type: String
    },
    p: {
      type: Object as PropType<String | Number>,
      required: false,
      default: "0px"
    },
    mt: {
      type: Object as PropType<String | Number>,
      required: false,
      default: "0px"
    },
    mb: {
      type: Object as PropType<String | Number>,
      required: false,
      default: "0px"
    },
    pt: {
      type: Object as PropType<String | Number>,
      default: "unset"
    }
  }
});
