
























import { defineComponent } from "vue";

const _slideWidth = 338;
const _slideHeight = (_slideWidth * 9) / 16;

export default defineComponent({
  setup() {
    const slideWidth = _slideWidth + "px";
    const slideHeight = _slideHeight + "px";

    const slides = [
      {
        src: "https://www.youtube.com/embed/1mCoWMgreGo?si=pmEBQNqi_rhkonlm",
        title: "Cuide do seu tempo: longevidade na sua rotina diária"
      },
      {
        src: "https://www.youtube.com/embed/ICXxj4-Oa60?si=qsVH9jLJDzHN8LZE",
        title: "Transforme sua vida: juventude e saúde em qualquer idade"
      }
    ];

    return {
      slideWidth,
      slideHeight,
      slides
    };
  }
});
