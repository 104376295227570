var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('OmtLayoutContainer',[_c('OmtRow',[_c('OmtTypography',{attrs:{"color":"var(--colors-light-primary-gray1)","as":"h2-bold"}},[_vm._v(" Omint Seguro Viagem")])],1),[(_vm.exibirCartaSeguroViagem)?_c('OmtRow',{attrs:{"mt":"16px","mb":"16px"},on:{"click":_vm.cartaSeguroViagem}},[_c('CardTile',_vm._b({scopedSlots:_vm._u([{key:"lead",fn:function(){return [_c('OmtIcons',{attrs:{"name":"angle-right","mr":"13","size":"xl","color":"var(--colors-light-primary-gray1)"}})]},proxy:true}],null,false,2777679459)},'CardTile',{
          icon: { name: 'earth-americas', size: '1x' },
          title: 'Carta Seguro Viagem',
          description: " Clientes Omint Premium e Omint Corporate contam com assistência médica e hospitalar, em casos de urgência\n            ou emergência, até o limite máximo do valor correspondente a € 30000,00 (trinta mil euros) incluída no seu\n            plano.",
          titleMarginBottom: '8px'
        },false))],1):_vm._e(),_c('OmtRow',{attrs:{"mt":"16px","mb":"16px"},on:{"click":_vm.cotacaoSeguroViagem}},[_c('CardTile',_vm._b({scopedSlots:_vm._u([{key:"lead",fn:function(){return [_c('OmtIcons',{attrs:{"name":"angle-right","mr":"13","size":"xl","color":"var(--colors-light-primary-gray1)"}})]},proxy:true}])},'CardTile',{
          icon: { name: 'plane', size: '1x' },
          title: 'Cotação Seguro Viagem',
          description: "<strong>Contrate capitais mais elevados e coberturas adicionais</strong> como extravio de bagagem, cancelamento de viagem,\n            entre outros. Aproveite as <strong>condições especiais</strong> para você, sua família e seus amigos.",
          titleMarginBottom: '8px'
        },false))],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }