<template>
  <CBox></CBox>
</template>

<script>
export default {
  mounted() {
    this.$root.$emit("hide-loading");
  }
};
</script>
<style scoped></style>
