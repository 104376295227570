











import IconRow from "@/components/Atoms/icon-row.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    IconRow
  }
});
