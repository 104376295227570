<template>
  <OmtGrid :minHeight="['auto', '629px']">
    <OmtRow mb="24px">
      <CBox>
        <OmtTypography as="h1-bold" mb="24px"> Envio das imagens </OmtTypography>

        <OmtTypography as="p1" :display="['block', 'none']">
          <b> Insira as imagens do Pedido Médico / Odontológico, e se necessário, documentos complementares </b>
          (relatórios, Laudos e etc.):
        </OmtTypography>
      </CBox>
    </OmtRow>

    <OmtRow :desktopStart="7">
      <ImageUploader @change="onImageUploaderChange" :initialValue="pictures" :length="limitFiles" :maxFileSize="25" />
    </OmtRow>

    <OmtRow :desktopStart="1" :desktopEnd="6" :gridRowStart="['initial', '2']">
      <CBox>
        <OmtTypography as="p1" mb="24px" :display="['none', 'block']">
          <b> Insira as imagens do Pedido Médico/Odontológico, e se necessário, documentos complementares </b>
          (relatórios, Laudos e etc.):
        </OmtTypography>

        <CFlex mb="16px">
          <OmtIcons name="check" size="lg" mr="16px" color="var(--colors-light-support-success)" />
          <OmtTypography as="p1"> <b>Arquivos suportados:</b> .jpg, .png, .heic e .pdf. com até 25mb. </OmtTypography>
        </CFlex>

        <CFlex mb="16px">
          <OmtIcons name="check" size="lg" mr="16px" color="var(--colors-light-support-success)" />
          <OmtTypography as="p1"> Envie apenas <b>um documento por foto</b>. </OmtTypography>
        </CFlex>

        <CFlex mb="16px">
          <OmtIcons name="check" size="lg" mr="16px" color="var(--colors-light-support-success)" />
          <OmtTypography as="p1"> Certifique-se de que todas as informações das imagens estão legíveis. </OmtTypography>
        </CFlex>
      </CBox>
    </OmtRow>

    <OmtRow position="sticky" bottom="0" pb="18px" pt="24px" bg="white" :desktopStart="7" :desktopEnd="12">
      <OmtButton @click="handleClick" :disabled="!canSubmit"> Continuar </OmtButton>
    </OmtRow>
  </OmtGrid>
</template>

<script>
import ImageUploader from "@/components/Molecules/ImageUploader";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  components: {
    ImageUploader
  },
  async mounted() {
    try {
      await this.$root.$emit("show-loading");
      if (!this.newAuth?.selectedBeneficiario || !this.newAuth?.selectedEvento) {
        this.$router.push({ name: "auths.create" });
        return;
      }

      this.limitFiles = await this.fetchMaxFiles();
    } finally {
      await this.$root.$emit("hide-loading");
    }

    if (this.newAuth && "anexos" in this.newAuth) {
      this.pictures = this.newAuth.anexos;
    }
  },
  data() {
    return {
      pictures: [],
      limitFiles: 0
    };
  },
  computed: {
    ...mapState("auths", ["newAuth"]),
    canSubmit() {
      const uploadedPictures = this.newAuth?.anexos?.filter(pic => pic.source && pic.name);
      return uploadedPictures?.length > 0;
    }
  },
  methods: {
    ...mapMutations("auths", ["SET_NEW_AUTH"]),
    ...mapActions("auths", ["fetchMaxFiles"]),
    handleClick() {
      this.$router.push({
        name: "auths.observation",
        query: { ...this.$route.query }
      });
    },
    onImageUploaderChange(pictures) {
      this.SET_NEW_AUTH({
        ...this.newAuth,
        anexos: pictures
      });
    }
  }
};
</script>
