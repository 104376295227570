/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import specific icons */
import {
  faAddressCard,
  faAngleRight,
  faArchive,
  faArrowDown,
  faArrowDownToBracket,
  faArrowLeft,
  faArrowRight,
  faArrowsRotate,
  faArrowUp,
  faArrowUpArrowDown,
  faArrowUpFromBracket,
  faBabyCarriage,
  faBars,
  faBell,
  faBellOn,
  faBuildingColumns,
  faBullseyeArrow,
  faCalendar,
  faCalendarClock,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleDollar,
  faCircleExclamation,
  faCircleXmark,
  faClipboardListCheck,
  faClock,
  faComment,
  faCommentPlus,
  faComments,
  faCreditCard,
  faCreditCardBlank,
  faEarthAmericas,
  faEllipsisVertical,
  faEnvelope,
  faEnvelopeCircleCheck,
  faEnvelopeOpenText,
  faExpand,
  faEye,
  faEyeSlash,
  faFaceLaughBeam,
  faFaceLaughWink,
  faFaceMeh,
  faFaceViewfinder,
  faFileCertificate,
  faFileCircleCheck,
  faFileInvoice,
  faFileInvoiceDollar,
  faFileMagnifyingGlass,
  faFileMedical,
  faFileWaveform,
  faFlask,
  faFolderMedical,
  faGear,
  faGift,
  faGrid,
  faHandHoldingHeart,
  faHandHoldingUsd,
  faHeart,
  faHeartbeat,
  faHospital,
  faHouse,
  faImage,
  faKey,
  faLanguage,
  faLightbulb,
  faLightbulbOn,
  faListAlt,
  faListTree,
  faLocationDot,
  faLock,
  faMedal,
  faMemo,
  faMemoCircleCheck,
  faMessagePlus,
  faMessageSmile,
  faMessageXmark,
  faMinus,
  faMobile,
  faMoneyBillTransfer,
  faMoneyCheckDollarPen,
  faMoon,
  faNewspaper,
  faNotesMedical,
  faPaperPlaneTop,
  faPenToSquare,
  faPhone,
  faPlane,
  faPlus,
  faPlusCircle,
  faSearch,
  faSearchMinus,
  faShareNodes,
  faSliders,
  faSignInAlt,
  faSpinnerThird,
  faSquareCheck,
  faSquarePollHorizontal,
  faStar,
  faStethoscope,
  faThumbsUp,
  faThumbTack,
  faTooth,
  faTrash,
  faTrashCan,
  faTruckMedical,
  faUser,
  faUserAlt,
  faUserDoctor,
  faUserGroup,
  faUserLock,
  faUserPlus,
  faUserSlash,
  faUsersMedical,
  faUserUnlock,
  faUserXmark,
  faVideo,
  faVirus,
  faWarning,
  faWindowClose,
  faXmark,
  faSyringe,
  faFilePdf
} from "@fortawesome/pro-light-svg-icons";

import {
  faWhatsapp,
  faInstagram,
  faFacebook,
  faLinkedin,
  faYoutube,
  faTwitter,
  faSpotify
} from "@fortawesome/free-brands-svg-icons";

import {
  faHeart as fasHeart,
  faStar as fasSolid,
  faCircleExclamation as fasCircleExclamatioin
} from "@fortawesome/pro-solid-svg-icons";

/* add icons to the library */
library.add(
  faAddressCard,
  faAngleRight,
  faArchive,
  faArrowDown,
  faArrowDownToBracket,
  faArrowLeft,
  faArrowRight,
  faArrowsRotate,
  faArrowUp,
  faArrowUpArrowDown,
  faArrowUpFromBracket,
  faBabyCarriage,
  faBars,
  faBell,
  faBellOn,
  faBuildingColumns,
  faBullseyeArrow,
  faCalendar,
  faCalendarClock,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleDollar,
  faCircleExclamation,
  faCircleXmark,
  faClipboardListCheck,
  faClock,
  faComment,
  faCommentPlus,
  faComments,
  faCreditCard,
  faCreditCardBlank,
  faEarthAmericas,
  faEllipsisVertical,
  faEnvelope,
  faEnvelopeCircleCheck,
  faEnvelopeOpenText,
  faExpand,
  faEye,
  faEyeSlash,
  faFacebook,
  faFaceLaughBeam,
  faFaceLaughWink,
  faFaceMeh,
  faFaceViewfinder,
  faFileCertificate,
  faFileCircleCheck,
  faFileInvoice,
  faFileInvoiceDollar,
  faFileMagnifyingGlass,
  faFileMedical,
  faFileMedical,
  faFileWaveform,
  faFlask,
  faFolderMedical,
  faFolderMedical,
  faGear,
  faGift,
  faGrid,
  faHandHoldingHeart,
  faHandHoldingUsd,
  faHeart,
  faHeartbeat,
  faHospital,
  faHouse,
  faImage,
  faInstagram,
  faKey,
  faLanguage,
  faLightbulb,
  faLightbulbOn,
  faLinkedin,
  faListAlt,
  faListTree,
  faLocationDot,
  faLocationDot,
  faLock,
  faMedal,
  faMemo,
  faMemoCircleCheck,
  faMessagePlus,
  faMessageSmile,
  faMessageXmark,
  faMinus,
  faMobile,
  faMoneyBillTransfer,
  faMoneyCheckDollarPen,
  faMoon,
  faNewspaper,
  faNotesMedical,
  faPaperPlaneTop,
  faPenToSquare,
  faPhone,
  faPhone,
  faPlane,
  faPlus,
  faPlusCircle,
  faSearch,
  faSearchMinus,
  faShareNodes,
  faShareNodes,
  faSignInAlt,
  fasHeart,
  faSliders,
  faSpinnerThird,
  faSpotify,
  faSquareCheck,
  faSquarePollHorizontal,
  fasSolid,
  faStar,
  faStethoscope,
  faThumbsUp,
  faThumbTack,
  faTooth,
  faTrash,
  faTrashCan,
  faTruckMedical,
  faTwitter,
  faUser,
  faUserAlt,
  faUserDoctor,
  faUserGroup,
  faUserLock,
  faUserPlus,
  faUserSlash,
  faUsersMedical,
  faUserUnlock,
  faUserXmark,
  faVideo,
  faVirus,
  faWarning,
  faWhatsapp,
  faWindowClose,
  faXmark,
  faYoutube,
  faSyringe,
  faFilePdf,
  fasCircleExclamatioin
);
