








import { defineComponent } from "vue";

export default defineComponent({
  async mounted() {
    await this.$root.$emit("hide-loading");
  },
  computed: {
    title() {
      switch (this.$route.name) {
        case "programas_de_saude.home":
          return "Programas de saúde";
        case "programas_de_saude.como_funciona":
          return "Como o programa funciona";
      }
    }
  }
});
