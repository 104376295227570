





























































import { defineComponent, PropType } from "vue";
import { CoverageAnalysesResultEnum } from "../CoverageAnalysesResult/index.vue";
import CoverageAnalysesResult from "../CoverageAnalysesResult/index.vue";

type CoverageAnalysesExam = {
  name: string;
  state: CoverageAnalysesResultEnum;
};

export type CoverageAnalysesItemModel = {
  date: Date;
  hasPendency: boolean;
  exams: CoverageAnalysesExam[];
};

export default defineComponent({
  components: {
    CoverageAnalysesResult
  },
  props: {
    data: {
      type: Object as PropType<CoverageAnalysesItemModel>,
      required: true
    }
  },
  methods: {
    convertDateToMonthAndYear(date: Date) {
      const _date = date.toLocaleString("pt-BR", {
        month: "long",
        year: "numeric"
      });
      return _date[0].toLocaleUpperCase() + _date.slice(1);
    },
    goAuthorization() {
      if (this.pendencyType === "authorization") {
        this.$router.push({
          path: "/auths/create",
          query: { origin: "analise-cobertura" }
        });
      }
    }
  },
  computed: {
    examsQuantity(): number {
      return this.data.exams.length;
    },
    hasPendency(): boolean {
      return this.data.hasPendency;
    },
    pendencyType(): CoverageAnalysesResultEnum | "both" {
      const pendencies = [];
      this.data.exams.forEach((e: CoverageAnalysesExam) =>
        pendencies.includes(e.state) || e.state === "coverage" ? null : pendencies.push(e.state)
      );
      let pendencyType: CoverageAnalysesResultEnum | "both";

      if (pendencies.length === 2) {
        pendencyType = "both";
      } else if (pendencies.length === 1) {
        pendencyType = pendencies[0];
      } else {
        pendencyType = "coverage";
      }

      return pendencyType;
    },
    pendencyText() {
      const hasPendency: boolean = this.hasPendency;
      const pendencyType: CoverageAnalysesResultEnum | "both" = this.pendencyType;
      if (hasPendency) {
        switch (pendencyType) {
          case "both":
            return "Enviar documentos";
          case "authorization":
            return "Enviar autorização";
          case "request":
            return "Enviar pedido";
          default:
            return "";
        }
      } else {
        switch (pendencyType) {
          case "both":
            return "Documentos enviados";
          case "authorization":
            return "Autorização enviada";
          case "request":
            return "Pedido enviado";
          default:
            return "";
        }
      }
    }
  }
});
