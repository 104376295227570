import { render, staticRenderFns } from "./ExamesSearch.vue?vue&type=template&id=f99c2026&scoped=true&"
import script from "./ExamesSearch.vue?vue&type=script&lang=js&"
export * from "./ExamesSearch.vue?vue&type=script&lang=js&"
import style0 from "./ExamesSearch.vue?vue&type=style&index=0&id=f99c2026&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f99c2026",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox})
