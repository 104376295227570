






























































































































import { defineComponent, getCurrentInstance, onMounted, ref } from "vue";
import EmphasysRow from "@/components/Atoms/emphasys-row.vue";
import { mapActions } from "vuex";

export default defineComponent({
  components: {
    EmphasysRow
  },
  methods: {
    ...mapActions("refund", ["setDontShowReceiptDeliveryNoticeAgain"]),
    ...mapActions("refund", ["setShowReceiptDeliveryNoticeOnInit"])
  },
  setup() {
    const context = getCurrentInstance().proxy;
    const inRefundNew = context.$route.path.includes("refund-new");
    const notShowAgainCheck = ref(false);

    onMounted(() => {
      context.$root.$emit("hide-back-button");
      context.$root.$emit("hide-header-options");
      context.$root.$emit("hide-loading");
    });

    const nextPage = async () => {
      if (inRefundNew) {
        if (notShowAgainCheck.value) {
          context.$root.$emit("show-loading");
          await context["setDontShowReceiptDeliveryNoticeAgain"]();
          context.$root.$emit("hide-loading");
        }
        context.$router.push({
          name: "refund.new.create"
        });
      } else {
        context.$root.$emit("show-loading");
        await context["setShowReceiptDeliveryNoticeOnInit"]();
        context.$root.$emit("hide-loading");
        context.$router.push("/");
      }
    };

    return {
      nextPage,
      inRefundNew,
      notShowAgainCheck
    };
  }
});
