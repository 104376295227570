<template>
  <OmtLayoutContainer>
    <OmtRow>
      <OmtTypography color="var(--colors-light-primary-gray1)" as="h2-bold"> Omint Seguro Viagem</OmtTypography>
    </OmtRow>

    <template>
      <OmtRow mt="16px" mb="16px" @click="cartaSeguroViagem" v-if="exibirCartaSeguroViagem">
        <CardTile
          v-bind="{
            icon: { name: 'earth-americas', size: '1x' },
            title: 'Carta Seguro Viagem',
            description: ` Clientes Omint Premium e Omint Corporate contam com assistência médica e hospitalar, em casos de urgência
              ou emergência, até o limite máximo do valor correspondente a € 30000,00 (trinta mil euros) incluída no seu
              plano.`,
            titleMarginBottom: '8px'
          }"
        >
          <template v-slot:lead>
            <OmtIcons name="angle-right" mr="13" size="xl" color="var(--colors-light-primary-gray1)" />
          </template>
        </CardTile>
      </OmtRow>

      <OmtRow mt="16px" mb="16px" @click="cotacaoSeguroViagem">
        <CardTile
          v-bind="{
            icon: { name: 'plane', size: '1x' },
            title: 'Cotação Seguro Viagem',
            description: `<strong>Contrate capitais mais elevados e coberturas adicionais</strong> como extravio de bagagem, cancelamento de viagem,
              entre outros. Aproveite as <strong>condições especiais</strong> para você, sua família e seus amigos.`,
            titleMarginBottom: '8px'
          }"
        >
          <template v-slot:lead>
            <OmtIcons name="angle-right" mr="13" size="xl" color="var(--colors-light-primary-gray1)" />
          </template>
        </CardTile>
      </OmtRow>
    </template>
  </OmtLayoutContainer>
</template>

<script>
import CardTile from "@/components/Molecules/CardTile";
import { mapState } from "vuex";

export default {
  components: {
    CardTile
  },
  mounted() {
    this.$root.$emit("hide-loading");
  },
  computed: {
    ...mapState("saude", ["perfil"]),
    exibirCartaSeguroViagem() {
      return !this.isExclusive;
    },
    isExclusive() {
      return this.$route.name === "safe-letter.intermediate.exclusivo";
    }
  },
  methods: {
    cartaSeguroViagem() {
      this.$router.push({ name: "safe-letter.list" });
    },
    cotacaoSeguroViagem() {
      this.$router.push({
        name: "external.link",
        query: { title: this.label },
        params: { link: `https://pages.omint.com.br/cotador-seguro-viagem/`, name: `Seguro Viagem` }
      });
    }
  }
};
</script>
