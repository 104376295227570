export const ROTA_BASE: string = "/";
export const ROTA_PADRAO: string = "/login";
export const ROTA_VINCULOS: string = "/vinculos";
export const ROTA_OPTIN: string = "/optin";
export const ROTA_ALTERAR_SENHA: string = "/change-password";
export const ROTA_WELCOME_ONBOARD_TOUR: string = "/welcome";
export const ROTA_AVISO_ATRASO: string = "/nonpayment";
export const ROTA_NOT_PAY: string = "/inadimplencia";
export const ROTA_AVISO_RECIBOS_REEMBOLSO: string = "/aviso-entrega-recibos-reembolso";

export const OPERACAO_OMINT: number = 1;
export const OPERACAO_KIPP: number = 4;

export const PERFIL_ASSOCIADO_OMINT: number = 1;
export const PERFIL_ASSOCIADO_SKILL: number = 1;
export const PERFIL_ASSOCIADO_KIPP: number = 25;

export function getRouteByPerfilNumber(operacao: number, perfil: number) {
  switch (operacao) {
    case OPERACAO_OMINT:
    case OPERACAO_KIPP:
      if (isAssociado(perfil)) {
        return ROTA_BASE;
      }
  }
  return ROTA_PADRAO;
}

export function isAssociado(perfil: number) {
  return perfil === PERFIL_ASSOCIADO_OMINT || perfil === PERFIL_ASSOCIADO_KIPP;
}
