import { getAuthToken, isLoggedIn } from "@/utils/authUtil";
import {
  getRouteByPerfilNumber,
  ROTA_ALTERAR_SENHA,
  ROTA_OPTIN,
  ROTA_AVISO_ATRASO,
  ROTA_PADRAO,
  ROTA_NOT_PAY
} from "@/utils/perfilRouteUtil";

import store from "@/store";

const hasAccessPermition = async to => {
  if (to.meta.allowAnonymous) return true;
  if (to.meta.checkPathDisabled) return true;
  const menu = store.state.saude.menu ?? [];
  let toPath = to.path;
  if (toPath.includes("?")) {
    toPath = toPath.substring(0, toPath.indexOf("?"));
  }
  if (toPath.endsWith("/")) {
    toPath = toPath.substring(0, toPath.length - 1);
  }
  const validPath = to.meta.validPath?.length ? to.meta.validPath : [toPath];
  let valid = !!validPath.find(p => !!menu.find(m => m.View && m.View.startsWith(p)));
  if (!valid) {
    const menusFavoritos = store.state.saude.menusFavoritos ?? [];
    valid = !!validPath.find(p => !!menusFavoritos.find(m => m.View && m.View.startsWith(p)));
  }
  if (valid && to.meta.validAction) {
    valid = await store.dispatch(to.meta.validAction);
  }
  return valid;
};

async function beforeEachRouteGuard(to, from, next) {
  if (!to.meta.offline && !navigator.onLine) {
    // rota permite apenas navegação online e está sem conexão com a internet
    window.dispatchEvent(new Event("offline"));
    next(false);
    return;
  }

  if (to.name === "logout") {
    // sem regra para sair da aplicação
    next();
    return;
  }

  const appLocked = store.state.accessManagement.appLocked;
  if (to.name === "login" && appLocked) {
    return next();
  }

  const vinculo = store.state.saude.vinculo;
  const operacao = store.state.saude.operacao;
  const perfil = store.state.saude.perfil;
  const routeByPerfil = operacao > 0 && perfil > 0 ? getRouteByPerfilNumber(operacao, perfil) : "";
  if (to.name === "login" && isLoggedIn(true)) {
    // login foi realizado (existe token de autenticação)
    if (vinculo === "" || perfil === 0 || operacao === 0) {
      // vinculo não foi selecionado, solicitado novo login
      next();
    } else {
      // autenticação realizada e vinculo do associado foi selecionado
      next({ path: routeByPerfil });
    }
    return;
  }

  const isLogged = isLoggedIn();
  if (isLogged && to.name === "vinculos" && (vinculo === "" || perfil === 0 || operacao === 0)) {
    next();
    return;
  }

  if (
    to.name !== "redirect" &&
    to.name !== "readonly.access" &&
    ((to.query.vinculo && to.query.login && to.query.usuario) || (to.query.cd1 && to.query.cd2 && to.query.cd4))
  ) {
    await store.dispatch("logoutUser");
    const authQuery = {
      nextUrl: to.path
    };
    Object.keys(to.query).map(k => {
      authQuery[k] = to.query[k];
    });
    next({
      name: "redirect",
      params: authQuery
    });
    return;
  }

  if (!to.meta.allowAnonymous && (!isLogged || vinculo === "" || perfil === 0 || operacao === 0)) {
    // será solicitada a autenticação se página de destino será exibida após o login
    if (to.fullPath === "" || to.fullPath === "/" || to.fullPath === "/vinculos" || to.fullPath === routeByPerfil) {
      next({
        path: ROTA_PADRAO
      });
    } else {
      next({
        path: ROTA_PADRAO,
        query: {
          nextUrl: to.fullPath
        }
      });
    }
    return;
  }

  if (isLogged) {
    if (!window.$axios.hasAuthToken()) {
      window.$axios.setAuthToken(getAuthToken());
    }
    if (!store.state.saude.hydrated && !store.state.saude.hydrating) {
      await store.dispatch("saude/hydrate");
    }
    const sessaoValida = await store.dispatch("myAccount/sessionState");
    if (sessaoValida) {
      if (
        store.state.myAccount?.terms?.length &&
        !to.path.includes(ROTA_OPTIN) &&
        !to.path.includes(ROTA_PADRAO) &&
        to.name !== "logout"
      ) {
        next({
          path: ROTA_OPTIN,
          query: {
            nextUrl: to.fullPath
          }
        });
        return;
      }

      if (store.state.accessManagement?.nonpaymentWarning?.key && to.path !== ROTA_AVISO_ATRASO) {
        next({ path: ROTA_AVISO_ATRASO });
        return;
      }

      if (store.state.accessManagement?.notPay && to.path !== ROTA_NOT_PAY) {
        next({ path: ROTA_NOT_PAY });
        return;
      }

      if (store.state.myAccount?.changePasswordRequired && to.path !== ROTA_ALTERAR_SENHA) {
        next({ path: ROTA_ALTERAR_SENHA });
        return;
      }

      const hasPermition = await hasAccessPermition(to);
      if (!hasPermition) {
        next(await store.dispatch("saude/fetchInitRoute"));
        return;
      }

      const registroNavegacao =
        vinculo !== "" &&
        to?.name &&
        from?.name &&
        (from.fullPath === "/" ||
          from.fullPath === routeByPerfil ||
          (to.name.indexOf(from.name) < 0 && from.name.indexOf(to.name) < 0));
      if (registroNavegacao) {
        if (to.meta?.registerAccess?.servico && to.meta?.registerAccess?.funcionalidade) {
          // registra acesso usuario (independente de idClassifica)
          store.dispatch("myAccount/registerAccess", {
            servico: to.meta.registerAccess.servico,
            funcionalidade: to.meta.registerAccess.funcionalidade,
            id: null
          });
        }
        if (to.meta?.idClassifica) {
          // registro de chamado de navegação
          // apenas para usuários autenticados e com vínculo selecionado
          // apenas rotas com classificação de rastreio (idClassifica)
          store.dispatch("myAccount/registerNavigation", {
            classificacao: to.meta?.idClassifica
          });
        }
      }
      next();
    } else {
      next("/logout");
    }
    next();
  } else {
    next();
  }
}

export default beforeEachRouteGuard;
