<script>
import { CPseudoBox } from "@chakra-ui/vue";
import Vue from "vue";

const mobileValidator = value => {
  if (value < 1 || value > 4) {
    console.error("Range allowed for mobile grid position must be between 1 and 4");
    console.error(`value used ${value}`);
    return false;
  }
  return true;
};

const desktopValidator = value => {
  if (value < 1 || value > 12) {
    console.error("Range allowed for desktop grid position must be between 1 and 12");
    console.error(`value used ${value}`);
    return false;
  }
  return true;
};

export default Vue.component("OmtRow", {
  props: {
    mobileStart: {
      type: Number,
      default: 1,
      validator: mobileValidator
    },
    desktopStart: {
      type: Number,
      default: 1,
      validator: desktopValidator
    },
    mobileEnd: {
      type: Number,
      default: 4,
      validator: mobileValidator
    },
    desktopEnd: {
      type: Number,
      default: 12,
      validator: desktopValidator
    },
    stack: {
      type: Boolean,
      default: false
    },
    gap: {
      type: String,
      default: "0"
    }
  },
  render: function (createElement) {
    return createElement(
      CPseudoBox,
      {
        on: { ...this.$listeners },
        class: ["omt-row", { stack: this.stack }],
        style: {
          display: "flex",
          alignItems: this.alignItems,
          justifyContent: this.justifyContent,
          "--grid-column-start-mobile": `${this.mobileStart}`,
          "--grid-column-start-desktop": `${this.desktopStart}`,
          "--grid-column-end-mobile": `${this.mobileEnd + 1}`,
          "--grid-column-end-desktop": `${this.desktopEnd + 1}`,
          ...(this.gap ? { gap: this.gap } : "")
        },
        attrs: {
          ...this.$attrs
        }
      },
      this.$slots.default
    );
  }
});
</script>

<style lang="scss">
.omt-row.stack {
  flex-direction: column;
}

/* mobile row */
.omt-row {
  grid-column-start: var(--grid-column-start-mobile);
  grid-column-end: var(--grid-column-end-mobile);

  & > * {
    width: 100%;
  }
}

/* desktop row */
@media screen and (min-width: 1024px) {
  .omt-row {
    grid-column-start: var(--grid-column-start-desktop);
    grid-column-end: var(--grid-column-end-desktop);
  }
}

.bottom-fixed {
  position: fixed;
  left: 0;
  right: 0;
  padding: 6px;
  background-color: white;
  justify-content: center;
  max-width: 1024px;
  width: initial;
  bottom: 66px;
  margin: initial;
}

@media screen and (min-width: 1024px) {
  .bottom-fixed {
    width: calc(100vw - 244px);
    max-width: 1104px;
    margin-left: -40px;
    padding: 40px;
    bottom: 0;
    left: initial;
    right: initial;
  }
}
</style>
