




































import { defineComponent, ref } from "vue";
import LabItem from "@/components/Molecules/LabItem/index.vue";
import SeeLabRulesPainel from "@/components/Molecules/SeeLabRulesPainel/index.vue";
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";

const labs = [
  {
    logo: "https://picsum.photos/24/24",
    name: "Fleury 1",
    address: "Rua dos Pinheiros,1392,PINHEIROS - São Paulo - SP",
    distance: " 2,42km"
  },
  {
    logo: "https://picsum.photos/24/24",
    name: "Fleury 2",
    address: "Rua dos Pinheiros,1392,PINHEIROS - São Paulo - SP",
    distance: " 2,42km"
  },
  {
    logo: "https://picsum.photos/24/24",
    name: "Fleury 3",
    address: "Rua dos Pinheiros,1392,PINHEIROS - São Paulo - SP",
    distance: " 2,42km"
  },
  {
    logo: "https://picsum.photos/24/24",
    name: "Fleury 4",
    address: "Rua dos Pinheiros,1392,PINHEIROS - São Paulo - SP",
    distance: " 2,42km"
  }
];

const address = {
  CEP: "52070-492",
  Endereco: "Rua Luiz Fariz, 03",
  UF: "PE"
};

export default defineComponent({
  components: {
    LabItem,
    SeeLabRulesPainel,
    FixedButtonRow
  },
  setup() {
    const search = ref("");
    const seeLab = () => null;
    return {
      labs,
      search,
      address,
      seeLab
    };
  }
});
