<template>
  <CBox class="rating-form" :class="theme" :mt="formMarginTop">
    <template v-if="avaliado">
      <omt-p><omt-bold>Obrigado pela avaliação</omt-bold></omt-p>
      <CBox v-if="ehAvaliacaoApp" pb="16px" width="100%" mt="16px">
        <OmtButton borderRadius="30px" width="100%" :bigSize="true" @click="fecharAvaliacao" variant="solid">
          Fechar
        </OmtButton>
      </CBox>
    </template>
    <template v-else>
      <CFlex justifyContent="start" w="100%" mb="5" v-if="ehSolicitacao">
        <OmtIcons name="check-circle" size="3x" color="var(--colors-light-support-success)" />
      </CFlex>
      <CBox class="card" v-if="ehSolicitacao">
        <CBox class="card-first-content">
          <OmtTypography as="h1-bold">Solicitação realizada com sucesso!</OmtTypography>
        </CBox>
        <CFlex w="100%">
          <CBox d="inline-flex">
            <CFlex><OmtIcons name="memo" size="lg" /></CFlex>
            <CFlex px="3">
              <OmtTypography as="h3-semi-bold" mb="4"> Protocolo: {{ this.protocolo }}</OmtTypography>
            </CFlex>
          </CBox>
        </CFlex>
        <OmtTypography as="p1" mb="16px">
          Você pode acompanhar o andamento desta solicitação pelo APP, WhatsApp e Site Omint.
        </OmtTypography>
        <hr />
      </CBox>
      <CFlex w="100%">
        <OmtTypography as="h3-bold" mb="5" v-if="ehSolicitacao">
          Avalie a facilidade de enviar essa solicitação:
        </OmtTypography>
        <template v-else>
          <CBox v-if="ehAvaliacaoApp">
            <OmtTypography as="h1-bold" mb="5">Está gostando do nosso APP?</OmtTypography>
          </CBox>
          <CBox v-else>
            <OmtTypography as="h3-bold" mb="5"> {{ textoAvaliacao }} </OmtTypography>
          </CBox>
        </template>
      </CFlex>

      <CFlex justify="space-between" mb="8px" w="100%">
        <OmtButton
          v-for="rate in 5"
          :key="rate"
          :variant="rate === rating ? 'solid' : 'outline'"
          width="48px"
          height="50px"
          border="0.5px solid var(--colors-light-secondary-divider)"
          borderRadius="50%"
          data-cy="rate-buttons"
          @click="setRating(rate)"
        >
          <OmtTypography as="p1" :color="rate === rating ? 'white' : 'var(--colors-light-primary-gray1)'">
            {{ rate }}
          </OmtTypography>
        </OmtButton>
      </CFlex>
      <CFlex justify="space-between" mb="22px" w="100%">
        <OmtTypography as="p2"> {{ textoNotaBaixa }} </OmtTypography>
        <OmtTypography as="p2"> {{ textoNotaAlta }} </OmtTypography>
      </CFlex>

      <CBox v-if="!!questions.length" class="rating-form_collapse">
        <CBox v-for="q in questions" :key="'q_' + q.value">
          <OmtTypography as="h3-semi-bold" mb="16px">
            {{ q.label }}
            <span v-if="q.required"> *</span>
          </OmtTypography>
          <OmtButton
            v-for="(opt, i) in q.options"
            :key="i"
            height="50px"
            border="0.5px solid var(--colors-light-secondary-divider)"
            borderRadius="30px"
            data-cy="rate-buttons"
            :variant="responses[q.value] === opt.value ? 'solid' : 'outline'"
            mb="32px"
            mr="8px"
            @click="setResponse(q.value, opt.value)"
          >
            <OmtTypography
              :color="responses[q.value] === opt.value ? 'white' : 'inherit'"
              :as="responses[q.value] === opt.value ? 'h3-bold' : 'h3-semi-bold'"
            >
              {{ opt.label }}
            </OmtTypography>
          </OmtButton>
        </CBox>
      </CBox>

      <CBox v-if="showObs" mb="8" class="rating-form_collapse-textarea">
        <OmtTypography as="h3-semi-bold" mb="3">{{
          obsLabel || "O que podemos melhorar? (obrigatório)"
        }}</OmtTypography>
        <CFlex flexDirection="column" alignItems="flex-end">
          <OmtTextarea
            :disabled="!rating"
            height="160px"
            padding="12px"
            :placeholder="obsPlaceholder"
            @change="obs = $event"
            :initialValue="obs"
            borderRadius="8px"
            border="0.5px solid #DADADA"
            mb="2"
          />
          <OmtTypography as="p2"> {{ obs.length }}/350 </OmtTypography>
        </CFlex>
      </CBox>

      <CBox v-if="existemPerguntasObrigatorias" class="rating-form_collapse-textarea">
        <OmtTypography as="h3-semi-bold" mb="8px"> * Preenchimento obrigatório </OmtTypography>
      </CBox>

      <CBox pb="16px" width="100%">
        <OmtButton
          :disabled="!canSendEvaluation"
          borderRadius="30px"
          width="100%"
          :bigSize="true"
          @click="submit()"
          variant="solid"
        >
          Concluir
        </OmtButton>
      </CBox>
    </template>
  </CBox>
</template>

<script>
import Options from "@/components/Molecules/Options";
import StarRating from "vue-star-rating";
import { CBox } from "@chakra-ui/vue";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  name: "RatingForm",
  components: {
    CBox,
    StarRating,
    Options
  },
  props: {
    protocolo: {
      type: String,
      default: ""
    },
    tipo: {
      type: String,
      default: ""
    },
    etapa: {
      type: String,
      default: ""
    },
    rotaDestino: {
      type: String,
      default: ""
    },
    textoAvaliacao: {
      type: String,
      default: "O que você achou deste serviço?"
    }
  },
  data() {
    return {
      allQuestions: [],
      questions: [],
      responses: {},
      rating: 0,
      obsLabel: "",
      obs: "",
      avaliado: false
    };
  },
  computed: {
    ...mapState("saude", ["baseUrl", "theme"]),
    ehSolicitacao() {
      return this.etapa === "S";
    },
    ehAvaliacaoApp() {
      return this.tipo === "601";
    },
    ehAvaliacaoPrestador() {
      return this.tipo === "600";
    },
    textoNotaBaixa() {
      return this.ehAvaliacaoPrestador ? "Muito insatisfeito" : "Muito difícil";
    },
    textoNotaAlta() {
      return this.ehAvaliacaoPrestador ? "Muito satisfeito" : "Muito fácil";
    },
    obsPlaceholder() {
      return this.ehAvaliacaoPrestador ? "Texto livre opcional" : "Conte-nos sobre como podemos melhorar";
    },
    formMarginTop() {
      const tamanhoPopup = 331;
      const tamanhoHeader = 80;
      if (!this.ehAvaliacaoApp) return "0px";
      if (this.questions?.length) return `${tamanhoHeader}px`;
      if (window.innerHeight < tamanhoPopup + tamanhoHeader)
        return `${tamanhoPopup + tamanhoHeader - window.innerHeight}px`;
      return "0px";
    },
    existemPerguntasObrigatorias() {
      const obrigatorias = this.questions.filter(q => q.required);
      return obrigatorias.length;
    },
    canSendEvaluation() {
      if (this.ehAvaliacaoPrestador && !this.rating) return false;
      if (!this.ehSolicitacao && !this.rating) return false;
      const emptyInput = this.questions.filter(q => q.required && !this.responses[q.value]);
      return !emptyInput.length;
    },
    showObs() {
      if (this.ehSolicitacao) return true;
      if (!this.allQuestions?.length) return true;
      return !!this.questions.find(q => q.maxRating >= this.rating);
    }
  },
  methods: {
    ...mapActions("ticket", ["saveExpRating", "fetchQuestionsExpRating"]),
    ...mapMutations("saude", ["CLEAR_PROTOCOLO_AVALIACAO_APP"]),
    ...mapGetters("biometrics", ["onNative"]),
    fecharAvaliacao() {
      document.querySelector(".swal2-close").click();
    },
    setRating(rating) {
      this.rating = rating;
      this.updateQuestions();
    },
    setResponse(question, option) {
      this.responses[question] = option;
      this.updateQuestions();
    },
    updateQuestions() {
      this.allQuestions.forEach(q => {
        const current = q.show;
        q.show = q.maxRating >= this.rating;
        if (q.show && q.exceptions?.length > 0) {
          for (let i = 0; i < q.exceptions.length; i++) {
            if (this.responses[q.exceptions[i].question] === q.exceptions[i].option) {
              q.show = false;
              break;
            }
          }
        }
        if (current && !q.show) {
          this.responses[q.value] = 0;
        }
      });
      this.questions = this.allQuestions.filter(q => q.show);
    },
    async submit() {
      let newRatingValue = null;
      try {
        await this.$root.$emit("show-loading");
        if (((this.ehSolicitacao && this.obs) || !this.ehSolicitacao) && this.rating >= 1 && this.rating <= 5) {
          newRatingValue = this.rating;
          const responses = [];
          Object.keys(this.responses).map(r => {
            if (this.responses[r] > 0) {
              responses.push({
                pergunta: r,
                resposta: this.responses[r]
              });
            }
          });
          await this.saveExpRating({
            ticket: this.protocolo,
            type: this.tipo,
            step: this.etapa,
            acknowledged: true,
            rating: newRatingValue,
            obs: this.obs,
            responses
          });
        }
      } finally {
        this.$emit("ratingSubmitClick", { newRatingValue });
        await this.$root.$emit("hide-loading");
        if (this.ehAvaliacaoApp) this.CLEAR_PROTOCOLO_AVALIACAO_APP();
        if (this.ehAvaliacaoApp && this.rating > 2 && this.onNative) {
          const yesOrNo = await Swal.fire({
            title: "Você gostaria de avaliar o app na loja de aplicativos?",
            text: "Sua avaliação é muito importante para nós!",
            confirmButtonText: "Avaliar",
            cancelButtonText: "Agora não",
            showCancelButton: true
          });
          if (yesOrNo.value) {
            const android = navigator.userAgent.match(/Android/i);
            if (android) {
              window.open("https://play.google.com/store/apps/details?id=br.com.omint.apps.minhaomint&a=pdf", "_blank");
            } else {
              window.open("https://apps.apple.com/br/app/minha-omint/id1115917955?action=write-review", "_blank");
            }
          }
        }
        if (this.rotaDestino) {
          await this.$router.replace(this.rotaDestino);
        } else {
          this.avaliado = true;
        }
      }
    }
  },
  async created() {
    if (!this.protocolo || !this.tipo) {
      this.$router.replace(this.rotaDestino);
      return;
    }
    try {
      await this.$root.$emit("show-loading");
      const rules = await this.fetchQuestionsExpRating({
        ticket: this.protocolo,
        type: this.tipo,
        stage: this.etapa
      });
      this.obsLabel = rules.label;
      this.allQuestions = rules.questions;
    } finally {
      if (!this.obsLabel) {
        this.obsLabel = this.etapa === "S" ? "Deixe um Comentário/Sugestão (opcional)" : "O que podemos melhorar?";
      }
      await this.$root.$emit("hide-loading");
    }
  }
};
</script>

<style lang="scss" scoped>
.center {
  text-align: center;
}

.dark .card {
  background-color: #1f1f1f;
}

.card {
  background-color: #ffffff;
  margin-bottom: 16px;

  .card-first-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    margin-bottom: 1rem;
  }

  p {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: var(--colors-light-primary-gray1);
  }

  h2:first-child {
    margin-bottom: 0.5rem;
  }
}
</style>
