import SafeLetterIndex from "@/pages/safe-letter/index.vue";
import SafeLetterCreate from "@/pages/safe-letter/create.vue";
import SafeLetterSuccess from "@/pages/safe-letter/success.vue";
import SafeLetterIntermediate from "@/pages/safe-letter/intermediate.vue";
import SafeLetterList from "@/pages/safe-letter/list.vue";

const routes: Route[] = [
  {
    path: "/seguro-viagem",
    component: SafeLetterIndex,
    children: [
      {
        path: "",
        name: "safe-letter.intermediate",
        component: SafeLetterIntermediate,
        meta: {
          backTo: "/",
          validPath: ["/seguro-viagem"]
        }
      },
      {
        path: "/carta",
        name: "safe-letter.list",
        component: SafeLetterList,
        meta: {
          backTo: "/seguro-viagem",
          validPath: ["/seguro-viagem"]
        }
      },
      {
        path: "create/:etapa",
        name: "safe-letter.create",
        component: SafeLetterCreate,
        meta: {
          validPath: ["/seguro-viagem"]
        }
      },
      {
        path: "success",
        name: "safe-letter.success",
        component: SafeLetterSuccess,
        meta: {
          backTo: "/seguro-viagem",
          validPath: ["/seguro-viagem"]
        }
      }
    ]
  },
  // dev: mantem compatibilidade com a rota antiga
  {
    path: "/carta-seguro-viagem",
    component: SafeLetterIndex,
    children: [
      {
        path: "",
        name: "safe-letter.intermediate",
        component: SafeLetterList,
        meta: {
          backTo: "/",
          validPath: ["/carta-seguro-viagem"]
        }
      }
    ]
  },
  {
    path: "/seguro-viagem-exclusivo",
    component: SafeLetterIndex,
    children: [
      {
        path: "",
        name: "safe-letter.intermediate.exclusivo",
        component: SafeLetterIntermediate,
        meta: {
          backTo: "/",
          validPath: ["/seguro-viagem"]
        }
      }
    ]
  }
];

export default routes;
