<template>
  <OmtGrid :minHeight="['auto', '629px']">
    <OmtRow :desktopStart="1" mb="24px">
      <CBox>
        <OmtTypography as="h1-regular" mb="24px"> <b>Observações</b> </OmtTypography>
        <OmtTypography as="p1">
          <CText class="information-subtitle" as="span" fontWeight="600"
            >Todas as suas informações necessárias foram preenchidas.</CText
          >
          Para finalizar, você gostaria de incluir alguma observação?
        </OmtTypography>
        <CFlex flexDirection="column" alignItems="flex-end" :pt="['24px', '48px']">
          <OmtTextarea
            height="160px"
            padding="12px"
            placeholder="Inclua informações ou detalhes que podem complementar os documentos enviados."
            @change="handleChange"
            :initialValue="obs"
          />
          <OmtTypography as="p2" data-cy="comments-count"> {{ obs.length }}/350 </OmtTypography>
        </CFlex>
      </CBox>
    </OmtRow>

    <OmtRow pb="18px" pt="24px" bg="white" :desktopStart="4" :desktopEnd="9">
      <OmtButton @click="submit()"> Enviar </OmtButton>
    </OmtRow>

    <ModalResponse
      title="Pedido de autorização enviado com sucesso"
      :isOpen.sync="showModal"
      :protocol="protocoloANS"
      :dueDate="dueDate"
      @close="onModalClose"
      @save="onSave"
    />
  </OmtGrid>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import ModalResponse from "@/components/Molecules/ModalResponse/index.vue";

export default {
  components: {
    ModalResponse
  },
  data() {
    return {
      showModal: false,
      obs: "",
      protocoloANS: "",
      dueDate: []
    };
  },
  async mounted() {
    await this.$root.$emit("hide-loading");
    if (!this.newAuth?.selectedBeneficiario || !this.newAuth?.selectedEvento || !this.newAuth?.anexos) {
      this.$router.push({ name: "auths.create" });
      return;
    }
    if (this.newAuth && "observacao" in this.newAuth) {
      this.obs = this.newAuth.observacao;
    }
  },
  computed: {
    ...mapState("auths", ["newAuth"])
  },
  methods: {
    ...mapActions("ticket", ["saveExpRating"]),
    ...mapActions("auths", ["createNewAuth", "fetchDueDate"]),
    ...mapMutations("auths", ["SET_NEW_AUTH", "SET_SUCCESS_NEW_AUTH"]),
    async submit() {
      await this.$root.$emit("show-loading");
      try {
        this.protocoloANS = await this.createNewAuth({
          obs: this.obs
        });
        if (this.protocoloANS) {
          this.dueDate = await this.fetchDueDate();
          this.showModal = true;
          this.SET_NEW_AUTH(null);
        } else {
          throw new Error("Erro ao criar autorização");
        }
      } catch (e) {
        await this.$toast({
          description: "Não foi possivel registrar o atendimento.\nServiço temporariamente indisponível.",
          status: "error",
          duration: 10000,
          position: "top"
        });
      } finally {
        await this.$root.$emit("hide-loading");
      }
    },
    handleChange(val) {
      this.obs = val;
      this.SET_NEW_AUTH({
        ...this.newAuth,
        observacao: val
      });
    },
    async onModalClose() {
      this.SET_SUCCESS_NEW_AUTH(true);
      this.redirectRoute();
    },
    async onSave(data) {
      await this.$root.$emit("show-loading");
      try {
        this.SET_NEW_AUTH(null);
        data.type = "4";
        data.step = "S";
        await this.saveExpRating(data);
      } finally {
        await this.$root.$emit("hide-loading");
        this.SET_SUCCESS_NEW_AUTH(true);
        this.redirectRoute();
      }
    },
    redirectRoute() {
      if (this.$route.query?.origin && this.$route.query.origin === "analise-cobertura") {
        this.$router.push({
          path: "/analise-cobertura"
        });
        return;
      }
      this.$router.push({
        path: "/autorizacao-lista"
      });
    }
  }
};
</script>

<style scoped>
::v-deep .information-subtitle {
  font-family: Open Sans, Roboto, sans-serif;
}
</style>
