<template>
  <div class="page" v-if="task">
    <div ref="publisher" id="publisher" class="publisher" />
    <div ref="subscriber" id="subscriber" class="subscriber" />

    <div class="bar" v-if="exibirChamada">
      <div class="icon function" @click.prevent="onMicClick">
        <i :class="isMicOpen ? 'fa fa-microphone' : 'fa fa-microphone-slash'"></i>
      </div>
      <div class="icon off" @click.prevent="onCloseClick">
        <i class="fa fa-phone"></i>
      </div>
      <div class="icon function" @click.prevent="onCamClick">
        <i :class="isCamOpen ? 'fa fa-video' : 'fa fa-video-slash'"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { importScript } from "@/utils/scriptUtil";
import { isAssociado } from "@/utils/perfilRouteUtil";

export default {
  name: "TaskVideoCall",
  data() {
    return {
      task: null,
      publisher: null,
      session: null,
      stream: null,
      exibirChamada: false,
      hasAudio: false,
      hasVideo: false,
      isMicOpen: false,
      isCamOpen: false
    };
  },
  computed: {
    ...mapGetters("saude", ["defaultRoute"]),
    ...mapState("saude", ["perfil"]),
    ...mapState("tasks", ["callInfos"]),
    apiKey() {
      return process.env.VUE_APP_OPENTOK_APIKEY;
    },
    apiJS() {
      return process.env.VUE_APP_BASE_URL + "scripts/opentok-client.2.28.6.min.js";
    },
    chamado() {
      return this.$route.query.chamado ? Number(this.$route.query.chamado) : 0;
    }
  },
  methods: {
    ...mapActions("myAccount", ["registerAccess"]),
    ...mapActions("tasks", ["featchTaskDetail", "featchVideoCallTokens", "fetchANSProtocol"]),
    ...mapMutations("ticket", ["SET_RATING_INFOS"]),
    closeCallSession() {
      try {
        if (this.publisher) {
          this.publisher.destroy();
          this.publisher = null;
        }
        if (this.session) {
          this.session.disconnect();
          this.session = null;
        }
      } catch (error) {
        window.$log.error("End call", error);
      }
    },
    async onCloseClick() {
      this.closeCallSession();
      this.registerAccess({ servico: "Videoconferência", funcionalidade: "Chamada encerrada", id: this.task.Chamado });
      const rotaInicial = this.defaultRoute;
      if (isAssociado(this.perfil)) {
        const protocol = await this.fetchANSProtocol({
          chamado: this.task.Chamado
        });
        if (protocol) {
          this.SET_RATING_INFOS({ ratingRoute: rotaInicial, ratingType: 8 });
          await this.$root.$emit("hide-loading");
          await this.$router.replace({
            path: `/experience-rating/${protocol}/8/P`
          });
          return;
        }
      }
      this.$router.push(rotaInicial);
    },
    async checkResources() {
      if (navigator && "mediaDevices" in navigator && "getUserMedia" in navigator.mediaDevices) {
        try {
          const audioStream = await navigator.mediaDevices.getUserMedia({
            audio: true
          });
          this.hasAudio = true;
          this.isMicOpen = true;
          const tracks = audioStream.getTracks();
          for (let i = 0; i < tracks.length; i++) {
            await tracks[i].stop();
          }
        } catch (error) {
          process.browser && window.$log.error("Error on checkResources audio", error);
        }

        try {
          const videoStream = await navigator.mediaDevices.getUserMedia({
            video: true
          });
          this.hasVideo = true;
          this.isCamOpen = true;
          const tracks = videoStream.getTracks();
          for (let i = 0; i < tracks.length; i++) {
            await tracks[i].stop();
          }
        } catch (error) {
          process.browser && window.$log.error("Error on checkResources video", error);
        }
      } else {
        this.hasAudio = true;
        this.isMicOpen = true;
        this.hasVideo = true;
        this.isCamOpen = true;
      }
    },
    onCamClick() {
      if (!this.hasVideo || !this.publisher) return;
      this.isCamOpen = !this.isCamOpen;
      this.publisher.publishVideo(this.isCamOpen);
    },
    onMicClick() {
      if (!this.hasAudio || !this.publisher) return;
      this.isMicOpen = !this.isMicOpen;
      this.publisher.publishAudio(this.isMicOpen);
    },
    async errorMessage() {
      await Swal.fire({
        title: "Entrevista indisponível",
        text: "Por favor, entre em contato com nosso atendimento pelo whatsapp",
        confirmButtonText: "OK"
      });
      this.$router.push(this.defaultRoute);
    },
    iniciarChamada(tentativa) {
      let sessaoCriada = false;
      try {
        if (this.session === null) {
          // eslint-disable-next-line no-undef
          this.session = OT.initSession(this.apiKey, this.callInfos.idSessao);
          sessaoCriada = this.session !== null;
        }
      } catch {
        setTimeout(() => this.iniciarChamada(++tentativa), 1000);
        return;
      }
      if (!sessaoCriada) {
        setTimeout(() => this.iniciarChamada(++tentativa), 1000);
        return;
      }
      this.session.connect(this.callInfos.TOKEN, error => {
        if (error) {
          if (tentativa < 2) {
            setTimeout(() => this.iniciarChamada(++tentativa), 1000);
          } else {
            window.$log.error("Entrevista indisponível", error);
            this.errorMessage();
          }
        } else {
          const aspet = 480 / 320;
          const area = window.innerWidth > window.innerHeight ? window.innerHeight : window.innerWidth;
          const publisherOptions = {
            name: isAssociado(this.perfil) ? "Associado" : "Staff",
            mirror: false,
            insertMode: "replace",
            width: 0.3 * area,
            height: 0.3 * area * aspet,
            style: { buttonDisplayMode: "off" },
            zIndex: 20,
            publishAudio: this.hasAudio,
            publishVideo: this.hasVideo
          };
          // eslint-disable-next-line no-undef
          this.publisher = OT.initPublisher(this.$refs.publisher, publisherOptions, error => {
            if (error) window.$log.error("initPublisher", error);
          });
          this.session.publish(this.publisher, error => {
            if (error) window.$log.error("publish", error);
          });

          this.exibirChamada = true;
        }
      });

      this.session.on("streamCreated", event => {
        if (this.stream?.streamId === event?.stream?.streamId) return;

        this.registerAccess({
          servico: "Videoconferência",
          funcionalidade: "Chamada iniciada",
          id: this.task.Chamado
        });

        const subscriberOptions = {
          name: isAssociado(this.perfil) ? "Staff" : "Associado",
          mirror: false,
          insertMode: "replace",
          width: window.innerWidth,
          height: window.innerHeight,
          fitMode: "contain"
        };
        this.stream = event.stream;
        this.session.subscribe(event.stream, this.$refs.subscriber, subscriberOptions, error => {
          if (error) window.$log.error("streamCreated", error);
        });
      });
      this.session.on("streamDestroyed", event => {
        if (this.stream?.streamId === event?.stream?.streamId) {
          this.stream = null;
        }
        if (event?.preventDefault) event.preventDefault();
      });
    }
  },
  beforeDestroy() {
    this.closeCallSession();
  },
  async created() {
    await importScript(document, this.apiJS);
  },
  async mounted() {
    try {
      if (this.chamado === 0) {
        return;
      }
      this.registerAccess({
        servico: "Videoconferência",
        funcionalidade: "Acesso a Videoconferência",
        id: this.chamado
      });
      this.task = await this.featchTaskDetail({ chamado: this.chamado });
      if (!this.task) {
        return;
      }
      await this.featchVideoCallTokens({
        chamado: this.task.Chamado,
        tpPessoa: this.task.TpPessoa
      });
      if (this.callInfos?.TOKEN) {
        await this.checkResources();
        this.$nextTick(() => this.iniciarChamada(1));
        await this.$root.$emit("hide-loading");
      }
    } catch (error) {
      window.$log.error("Erro ao carregar a tarefa", error);
    } finally {
      if (!this.task) {
        await this.$router.push(this.defaultRoute);
      } else if (!this.callInfos?.TOKEN) {
        await this.$router.push(`/video-call/pre-call/${this.task.Chamado}`);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.page {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  border: 0;
  & .publisher {
    position: absolute;
    border: 2px solid white;
    border-radius: 3px;
    top: 33px;
    right: 0.5rem;
    z-index: 20;
  }
  & .subscriber {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw !important;
    height: 100vh !important;
    z-index: 10;
  }
  & .bar {
    width: 100%;
    bottom: 0;
    left: 0;
    margin-top: auto;
    position: absolute;
    border-width: 0px;
    height: 96px;
    text-align: center;
    background: rgba(0, 0, 0, 0.35);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    z-index: 50;
    & .icon {
      margin: 20px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      text-align: center;
      color: white;
      & i {
        margin-top: 12px;
        margin-left: 1px;
        font-size: 24px !important;
      }
    }
    & .function {
      background-color: rgba(255, 255, 255, 0.4);
    }
    & .off {
      transform: rotate(-135deg);
      background-color: red;
    }
  }
}
</style>
