import * as geoUtils from "@/utils/geoDistance";
import { getStorageData } from "@/utils/storageUtil";

const baseURL = `${process.env.VUE_APP_SERVER_URL}/Associado`;

export async function ObterCredencialEletronica({ codigoTitulo }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterCredencialEletronicaPWA`,
      query: {
        vinculo: codigoTitulo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterCredencialEletronica", error.message);
      return error;
    });
}

export async function ObterBeneficiariosTitulo({ vinculo, dependentes, servico }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterBeneficiariosTitulo`,
      query: {
        vinculo,
        dependentes,
        servico
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterBeneficiariosTitulo", error.message);
      return error;
    });
}

export async function ListarContaCorrenteCredito({ vinculo, beneficiario, servico }) {
  return window.$axios
    .get({
      url: `${baseURL}/ListarContaCorrenteCredito`,
      query: {
        vinculo,
        beneficiario,
        servico
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ListarContaCorrenteCredito", error.message);
      return error;
    });
}

export async function ListarBeneficiarioContaCredito({ vinculo, servico }) {
  return window.$axios
    .get({
      url: `${baseURL}/ListarBeneficiarioContaCredito`,
      query: {
        vinculo,
        servico
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ListarBeneficiarioContaCredito", error.message);
      return error;
    });
}

export async function GerarCredencialEletronicaCompartilhada({ vinculo, tipoCredencial }) {
  return window.$axios
    .get({
      url: `${baseURL}/GerarCredencialEletronicaCompartilhada`,
      query: {
        vinculo,
        tipo: tipoCredencial
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("GerarCredencialEletronicaCompartilhada", error.message);
      return error;
    });
}

export async function ObterCredencialEletronicaCompartilhada({ chave }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterCredencialEletronicaCompartilhada`,
      query: {
        chave
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterCredencialEletronicaCompartilhada", error.message);
      return error;
    });
}

export async function MeusDocumentos({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/MeusDocumentos`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("MeusDocumentos", error.message);
      return error;
    });
}

export async function ObterEmails({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterEmails`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterEmails", error.message);
      return error;
    });
}

export async function ObterContaCorrenteParaCredito({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterContaCorrenteParaCredito`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterContaCorrenteParaCredito", error.message);
      return error;
    });
}

export async function ObterBancosDisponiveis({ codigo, descricao, autorizaDebito, autorizaCredito }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterBancosDisponiveis`,
      query: {
        codigo,
        descricao,
        autorizaDebito,
        autorizaCredito
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterBancosDisponiveis", error.message);
      return error;
    });
}

export async function ObterAgenciasDisponiveis({ banco, codigo, descricao }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterAgenciasDisponiveis`,
      query: {
        banco,
        codigo,
        descricao
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterAgenciasDisponiveis", error.message);
      return error;
    });
}

export async function AlterarContaCredito({
  vinculo,
  banco,
  agencia,
  conta,
  digito,
  emailContato,
  telefoneContato,
  emailLogado,
  usuarioLogado
}) {
  const coords: any = await geoUtils.GetLocation().catch(() => null);
  return window.$axios
    .post({
      url: `${baseURL}/AlterarContaCredito`,
      query: {
        vinculo,
        banco,
        agencia,
        conta,
        digito,
        emailContato,
        telefoneContato,
        emailLogado,
        usuarioLogado,
        lat: coords?.latitude,
        lng: coords?.longitude
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("AlterarContaCredito", error.message);
      return error;
    });
}

export async function CadastroContaCredito({
  vinculo,
  beneficiario,
  idConta,
  banco,
  agencia,
  conta,
  digito,
  excluir,
  principal,
  emailContato,
  telefoneContato,
  usuarioLogado,
  autenticacao
}) {
  const coords: any = await geoUtils.GetLocation().catch(() => null);
  return window.$axios
    .post({
      url: `${baseURL}/CadastroContaCredito`,
      query: {
        vinculo,
        lat: coords?.latitude,
        lng: coords?.longitude
      },
      data: {
        beneficiario,
        idConta,
        banco,
        agencia,
        conta,
        digito,
        principal,
        excluir,
        emailContato,
        telefoneContato,
        usuarioLogado,
        autenticacao
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("CadastroContaCredito", error.message);
      return error;
    });
}

export async function ConsultarRequisicaoAlteracaoContaCredito({ chave }) {
  return window.$axios
    .get({
      url: `${baseURL}/ConsultarRequisicaoAlteracaoContaCredito`,
      query: {
        chave
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ConsultarRequisicaoAlteracaoContaCredito", error.message);
      return error;
    });
}

export async function ListarFaturas({ vinculo }) {
  return window.$axios
    .post({
      url: `${baseURL}/ListaFaturas`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ListaFaturas", error.message);
      return error;
    });
}

export async function ObterFatura({ vinculo, nrTitulo }) {
  const chave = `${vinculo};${nrTitulo}`;
  const key = `${window.$jse.encrypt(chave)}`;
  const type = ".pdf";
  const queryString = new URLSearchParams({
    key,
    type
  }).toString();

  return `${baseURL}/ObterFaturaPWA?${queryString}`;
}

export async function ListarAcessosFuncionalidades({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/ListarAcessosFuncionalidades`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data.Data)
    .catch(error => {
      window.$log.error("ListarAcessosFuncionalidades", error.message);
      return error;
    });
}

export async function AtualizarAcessoPermissao({ vinculoDestinatario, ativar, idPermissao, vinculo }) {
  const coords: any = await geoUtils.GetLocation().catch(() => null);
  return window.$axios
    .post({
      url: `${baseURL}/AtualizarAcessoPermissao`,
      query: {
        vinculo,
        idPermissao,
        vinculoDestinatario,
        ativar,
        dsUsuario: getStorageData("username", ""),
        lat: coords?.latitude,
        lng: coords?.longitude
      }
    })
    .then(resp => resp.data.Data)
    .catch(error => {
      window.$log.error("AtualizarAcessoPermissao", error.message);
      return error;
    });
}

export async function AtualizarAcessoFuncionalidades({ vinculo, permissoes }) {
  const coords: any = await geoUtils.GetLocation().catch(() => null);
  return window.$axios
    .post({
      url: `${baseURL}/AtualizarAcessoFuncionalidades`,
      query: {
        vinculo,
        dsUsuario: getStorageData("username", ""),
        lat: coords?.latitude,
        lng: coords?.longitude
      },
      data: [...permissoes]
    })
    .then(resp => resp.data.Data)
    .catch(error => {
      window.$log.error("AtualizarAcessoFuncionalidades", error.message);
      return error;
    });
}

export async function VerificaOptinPendente({ vinculo, template, sistema }) {
  return window.$axios
    .get({
      url: `${baseURL}/VerificaOptinPendente`,
      query: {
        vinculo,
        template,
        sistema
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("VerificaOptinPendente", error.message);
      return error;
    });
}

export async function AceitaOptin({ vinculo, template, login }) {
  return window.$axios
    .post({
      url: `${baseURL}/AceitaOptin`,
      query: {
        vinculo,
        template,
        login
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("AtualizarAcessoFuncionalidades", error.message);
      return error;
    });
}

export async function VerificarAvisoInadimplencia({ vinculo }) {
  return window.$axios
    .get({
      url: `${baseURL}/VerificarAvisoInadimplencia`,
      query: {
        vinculo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("VerificarAvisoInadimplencia", error.message);
      return error;
    });
}

export async function RegistarCienteAvisoInadimplencia({ vinculo, chave }) {
  const coords: any = await geoUtils.GetLocation().catch(() => null);
  return window.$axios
    .post({
      url: `${baseURL}/RegistarCienteAvisoInadimplencia`,
      query: {
        vinculo
      },
      data: {
        chave,
        lat: coords?.latitude,
        lng: coords?.longitude
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("RegistarCienteAvisoInadimplencia", error.message);
      return error;
    });
}

export async function RegistarInadimplenciaPessoaFisica({ vinculo, titulo }) {
  return window.$axios
    .post({
      url: `${baseURL}/RegistarCienteAvisoInadimplencia`,
      query: {
        vinculo,
        titulo
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("RegistarCienteAvisoInadimplencia", error.message);
      return error;
    });
}

export async function ObterBeneficiariosAcessoFuncionalidade({ vinculo, funcionalidade }) {
  return window.$axios
    .get({
      url: `${baseURL}/ObterBeneficiariosAcessoFuncionalidade`,
      query: {
        vinculo,
        funcionalidade
      }
    })
    .then(resp => resp.data)
    .catch(error => {
      window.$log.error("ObterBeneficiariosAcessoFuncionalidade", error.message);
      return error;
    });
}
