









import { defineComponent, PropType } from "vue";

export type CoverageAnalysesResultEnum = "coverage" | "authorization" | "request";

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true
    },
    result: {
      type: String as PropType<CoverageAnalysesResultEnum>,
      required: true,
      validator: function (value: string) {
        const isValid = ["coverage", "authorization", "request"].includes(value);
        if (!isValid) {
          console.error("Property 'result' dont belong to CoverageAnalysesResultEnum");
        }
        return isValid;
      }
    }
  },
  computed: {
    color() {
      switch (this.result as CoverageAnalysesResultEnum) {
        case "coverage":
          return "var(--colors-light-support-success)";
        case "request":
          return "var(--colors-light-support-error)";
        case "authorization":
          return "var(--colors-light-support-warning)";
      }
    },
    icon() {
      switch (this.result as CoverageAnalysesResultEnum) {
        case "coverage":
          return "circle-check";
        case "request":
          return "circle-xmark";
        case "authorization":
          return "circle-exclamation";
      }
    },
    text() {
      switch (this.result as CoverageAnalysesResultEnum) {
        case "coverage":
          return "Exame com cobertura.";
        case "request":
          return "Exame não encontrado. Envie o pedido médico para análise de cobertura.";
        case "authorization":
          return "Exame encontrado, porém necessita de autorização prévia.";
      }
    }
  }
});
