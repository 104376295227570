






































import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    logo: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    address: {
      type: String,
      required: true
    },
    distance: {
      type: String,
      required: true
    },
    price: {
      type: String,
      required: false
    },
    isDisabled: {
      type: Boolean,
      required: false
    },
    isSelected: {
      type: Boolean,
      required: false,
      default: undefined
    },
    showDetailsAction: {
      type: Function as PropType<() => void>,
      required: false
    }
  },
  emits: ["selection"]
});
