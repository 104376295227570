import HealthPrograms from "@/pages/health-programs/index.vue";
import HealthProgramsHome from "@/pages/health-programs/home.vue";
import HealthProgramsHowWorks from "@/pages/health-programs/how_it_works.vue";

const routes: Route[] = [
  {
    path: "/programas-de-saude",
    name: "programas_de_saude",
    component: HealthPrograms,
    children: [
      {
        path: "",
        name: "programas_de_saude.home",
        component: HealthProgramsHome,
        meta: {
          backTo: "/",
          validPath: ["/"]
        }
      },
      {
        path: "como-funciona",
        name: "programas_de_saude.como_funciona",
        component: HealthProgramsHowWorks,
        meta: {
          backTo: "/programas-de-saude",
          validPath: ["/", "/programas-de-saude/"]
        }
      }
    ]
  }
];

export default routes;
