<template>
  <OmtLayoutContainer>
    <OmtRow mb="8px">
      <OmtTypography as="h1-bold"> Resultados </OmtTypography>
      <OmtTypography v-if="simulationCreationDate" as="p2" text-align="right">
        Simulado em {{ simulationCreationDate }}
      </OmtTypography>
    </OmtRow>

    <OmtRow mb="16px">
      <OmtTypography as="p2">
        Confira os valores de coparticipação por prestador para os exames selecionados:
      </OmtTypography>
    </OmtRow>

    <OmtRow mb="8px">
      <OmtTypography as="h2-bold"> Laboratórios </OmtTypography>
    </OmtRow>

    <OmtRow
      v-for="(lab, index) in selectedLaboratories"
      :key="'lab-item_' + index"
      ml="-16px"
      min-width="calc(100% + 32px)"
      mb="6px"
      stack
    >
      <OmtDivider v-if="index !== 0" w="calc(100% - 32px) !important" m="0 auto !important" />
      <LabItem
        :logo="lab.logo"
        :name="lab.name"
        :address="lab.address"
        :distance="lab.distance"
        :price="lab.price"
        :show-details-action="() => showLabDetails(lab)"
      />
    </OmtRow>

    <OmtRow mb="24px">
      <OmtTypography as="p2">
        Os valores são informativos e foram estimados com base na tabela vigente em {{ simulationValuesDateRef }}.
        <br />
        No momento da realização o prestador fará o correto enquadramento com base no pedido médico e na técnica
        solicitada, podendo levar a alterações dos valores de coparticipação calculados. <br />
        A confirmação de realização dos exames deve ser feita diretamente com os laboratórios.
      </OmtTypography>
    </OmtRow>

    <OmtRow mb="24px">
      <OmtTypography as="h2-bold"> Exames selecionados </OmtTypography>
      <OmtTypography as="p2" color="var(--colors-light-primary-gray2)" text-align="right">
        {{ selectedExamsCount }} exame(s)
      </OmtTypography>
    </OmtRow>

    <OmtRow gap="16px" stack>
      <OmtCardTile
        v-for="(item, index) in selectedExams"
        :key="index"
        :icon="{ name: 'clipboard-list-check', size: 'lg' }"
        :title="item.name"
        cursor="initial"
        class="selected-exams-tiles"
      />
    </OmtRow>

    <FixedButtonRow>
      <OmtRow gap="16px" stack>
        <OmtButton :w="['100%', '308px !important']" @click="finish" mx="auto" mb="16px"> Concluir </OmtButton>
        <CFlex justify-content="center" cursor="pointer" @click="shareSimulation" pb="16px" v-show="false">
          <OmtIcons name="share-nodes" mr="16px" size="lg" />
          <OmtTypography as="button-small"> Compartilhar simulação </OmtTypography>
        </CFlex>
      </OmtRow>
    </FixedButtonRow>
  </OmtLayoutContainer>
</template>

<script>
import { mapActions, mapState } from "vuex";
import OmtCardTile from "@/components/Molecules/CardTile/index.vue";
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";
import LabItem from "@/components/Molecules/LabItem/index.vue";

export default {
  components: {
    OmtCardTile,
    FixedButtonRow,
    LabItem
  },
  async mounted() {
    try {
      this.$root.$emit("show-loading");
      if (this.simulationId) {
        const found = await this.fetchSimulationDetail({ id: this.simulationId });
        if (!found) {
          this.$router.push("/copay/simulations");
          return;
        }
        this.$root.$emit("change-back-route", "/copay/simulations");
      } else {
        if (!this.selectedLaboratories.length || !this.selectedExams.length) {
          this.$router.push("/copay/simulations/create/select-labs");
          return;
        }
        // update list on backgound
        this.fetchSimulations();
        this.$root.$emit("change-back-route", "/copay/simulations/create/review");
      }
    } catch (error) {
      console.error("copay load error", error);
    } finally {
      this.$root.$emit("hide-loading");
    }
  },
  computed: {
    ...mapState("copay", [
      "simulations",
      "selectedLaboratories",
      "selectedExams",
      "simulationValuesDateRef",
      "simulationCreationDate"
    ]),
    simulationId() {
      return "id" in this.$route.params ? this.$route.params.id : null;
    },
    selectedExamsCount() {
      return this.$store.state.copay.selectedExams.length;
    }
  },
  methods: {
    ...mapActions("copay", ["fetchSimulationDetail", "fetchSimulations"]),
    showLabDetails(lab) {
      if (!lab?.CodigoPrestador) return;
      const rede = lab.CodigoRede ? lab.CodigoRede : "0000";
      this.$router.push({
        name: "affiliate.docInfo",
        params: {
          situacao: "A",
          rede,
          cdPrestador: lab.CodigoPrestador,
          srEndereco: lab.SrEndereco
        }
      });
    },
    finish() {
      this.$root.$emit("show-back-button");
      this.$router.push({
        path: "/copay/simulations"
      });
    },
    shareSimulation() {}
  }
};
</script>

<style scoped>
::v-deep.selected-exams-tiles div[data-chakra-component="CFlex"] {
  cursor: initial !important;
}
</style>
