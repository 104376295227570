<template>
  <CBox>
    <template v-if="task">
      <CBox v-show="displayTerms" mb="24px">
        <div v-if="task.Optin" v-html="task.Optin"></div>
      </CBox>
      <CFlex v-show="!displayTerms" direction="column">
        <CBox mb="24px">
          <OmtTypography as="p1">Selecione abaixo a melhor data e horário.</OmtTypography>
        </CBox>
        <CBox w="100%" mb="24px">
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)"
            >Local para realizar a consulta
          </OmtTypography>
          <OmtSelect
            placeholder="Selecione o local"
            :disabled="isLoading"
            :initialValue="selectedLocal"
            @change="e => onLocalSelection(e)"
          >
            <option v-for="l in locaisDisponiveis" :key="`local-${l.value}`" :value="l.value">
              {{ l.label }}
            </option>
          </OmtSelect>
          <OmtTypography as="p2" v-if="selectedAddress">{{ selectedAddress }}</OmtTypography>
        </CBox>

        <CBox w="100%" mb="28px">
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)"
            >Beneficiário que realizará a consulta
          </OmtTypography>
          <OmtSelect
            placeholder="Selecione o beneficiário"
            :disabled="isLoading"
            :initialValue="selectedBeneficiary"
            @change="e => onBeneficiarySelection(e)"
          >
            <option v-for="b in beneficiariosDisponiveis" :key="`bene-${b.value}`" :value="b.value">
              {{ b.label }}
            </option>
          </OmtSelect>
        </CBox>

        <div v-show="false">
          <div ref="sucessMessage">
            <CFlex direction="column" pb="16px" pt="16px">
              <CFlex direction="row" mb="16px">
                <OmtIcons name="check-circle" size="lg" mr="8px" />
                <OmtTypography as="h2-bold">Agendamento realizado com sucesso!</OmtTypography>
              </CFlex>
              <OmtTypography as="p1">
                Seu agendamento foi realizado com sucesso e já está disponível na área
                <b>Minhas Atividades</b>. <br /><br />
                Caso seja necessário cancelar ou reagendá-lo, clique em <b>Ver detalhes</b> e escolha a nova data para
                atendimento.
              </OmtTypography>
            </CFlex>
          </div>
        </div>

        <v-calendar
          ref="calendar"
          titlePosition="center"
          border="none"
          is-expanded
          v-chakra
          mb="32px"
          color="indigo"
          :attributes="calendarAttributes"
          :available-dates="datasDisponiveis"
          @dayclick="onDayClick"
        />

        <CBox v-if="!isLoading && horariosDisponiveis.length > 0" w="100%" mb="16px">
          <OmtTypography as="h2-bold">Horários disponíveis</OmtTypography>
          <CFlex direction="row" flex-wrap="wrap" justify="flex-start" mt="16px">
            <CBox v-for="s in horariosDisponiveis" :key="s" w="33%" p="8px">
              <OmtButton
                size="sm"
                w="100%"
                :variant="selectedTime === s ? 'solid' : 'outline-extra-gray'"
                @click.prevent="onTimeClick(s)"
              >
                <OmtTypography as="p2" fontWeight="500" :color="selectedTime === s ? 'white' : 'inherit'">
                  {{ s }}
                </OmtTypography>
              </OmtButton>
            </CBox>
          </CFlex>
        </CBox>

        <CBox v-if="!isLoading && selectedTime" w="100%" mb="24px">
          <OmtTypography as="p1" color="var(--colors-light-primary-gray2)">Profissional para agendamento</OmtTypography>
          <OmtSelect
            placeholder="Selecione o profissional"
            :disabled="isLoading"
            :initialValue="selectedLocal"
            @change="e => onStaffSelection(e)"
          >
            <option v-for="s in staffsDisponiveis" :key="`staff-${s.value}`" :value="s.value">
              {{ s.label }}
            </option>
          </OmtSelect>
        </CBox>

        <CBox v-if="!displayTerms" w="100%" mb="30px">
          <SwitchCheckBox :disabled="!selectedBeneficiary" :onChange="val => (termsCheck = val)" :rounded="true">
            <CBox cursor="pointer" @click.prevent="showTerms()">
              <OmtTypography as="p2" color="var(--colors-light-primary-gray2)">
                Li os
                <omt-bold style="color: #172972"><u>termos gerais (consentimento livre e esclarecido)</u> </omt-bold>.
                Compreendi, concordo e aceito.
              </OmtTypography>
            </CBox>
          </SwitchCheckBox>
        </CBox>
      </CFlex>
    </template>

    <FixedButtonRow :useFooter="false" :solidColor="true">
      <OmtButton v-if="displayTerms" @click.prevent="hideTerms" :w="['100%', '308px !important']" variant="solid">
        Fechar
      </OmtButton>
      <OmtButton
        v-else
        @click.prevent="onSubmit"
        :w="['100%', '308px !important']"
        variant="solid"
        :disabled="disableButton"
      >
        <OmtTypography
          as="h1-bold"
          :color="disableButton ? 'var(--colors-light-secondary-disabled-placeholder)' : 'white'"
          >Agendar
        </OmtTypography>
      </OmtButton>
    </FixedButtonRow>
  </CBox>
</template>

<script>
import { mapActions } from "vuex";
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";
import BlockList from "@/components/Molecules/BlockList";
import Options from "@/components/Molecules/Options";
import SwitchCheckBox from "@/components/Molecules/SwitchCheckBox";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

const TIPO_OPERACAO = 1; // APS - EQUIPE SAÚDE

export default {
  components: { BlockList, Options, SwitchCheckBox, Swal, FixedButtonRow },
  data() {
    return {
      task: null,
      agenda: null,
      displayTerms: false,
      termsCheck: false,
      selectedBeneficiary: "",
      selectedLocal: "",
      selectedAddress: "",
      selectedSpeciality: "",
      selectedStaff: "",
      selectedDate: "",
      selectedTime: "",
      diaAgenda: null,
      horariosDisponiveis: [],
      isLoading: true,
      calendarAttributes: [
        {
          key: "selected-date",
          highlight: {
            contentClass: "date-selected"
          },
          dates: null
        }
      ]
    };
  },
  computed: {
    beneficiariosDisponiveis() {
      if (!this.task?.Beneficiarios) return [];
      return this.task.Beneficiarios.map(l => ({
        value: l.Codigo,
        label: l.Nome
      }));
    },
    locaisDisponiveis() {
      if (!this.task?.Locais) return [];
      return this.task.Locais.map(l => ({
        value: l.Id,
        label: l.Nome
      }));
    },
    staffsDisponiveis() {
      if (!this.task?.Staffs) return [];

      const horario = this.diaAgenda?.Horarios?.find(h => h.Hora === this.selectedTime);
      if (!horario?.Staffs) return [];
      const staffsValidos = this.task.Staffs.filter(s => horario.Staffs.find(sh => sh.IdStaff === s.Id));
      const staffs = staffsValidos.map(s => ({
        value: s.Id,
        label: s.Nome
      }));
      return staffs;
    },
    datasDisponiveis() {
      if (!this.agenda) return new Date(1, 0, 0);
      const dias = this.agenda.map(d => new Date(d.Ano, d.Mes - 1, d.Dia)) ?? [];
      return dias.length > 0 ? dias : new Date(1, 0, 0);
    },
    disableButton() {
      if (!this.termsCheck) return true;
      if (!this.selectedBeneficiary) return true;
      if (!this.selectedLocal) return true;
      if (!this.selectedSpeciality) return true;
      if (!this.selectedStaff) return true;
      if (!this.diaAgenda?.Dia) return true;
      if (!this.diaAgenda?.Mes) return true;
      if (!this.diaAgenda?.Ano) return true;
      if (!this.selectedTime) return true;
      return false;
    }
  },
  methods: {
    ...mapActions("myAccount", ["checkNextWelcomeOnBoardTour"]),
    ...mapActions("tasks", ["featchTaskRules", "featchCalendar", "createTask"]),
    hideTerms() {
      this.$root.$emit("welcome-edit-close", undefined);
      this.displayTerms = false;
      window.scrollTo(0, document.body.scrollHeight);
    },
    showTerms() {
      this.$root.$emit("welcome-edit-close", this.hideTerms);
      window.scrollTo(0, 0);
      this.displayTerms = true;
      this.termsCheck = false;
    },
    controleLoading(alterarLoading, loading) {
      if (alterarLoading) {
        this.isLoading = loading;
        this.$root.$emit(loading ? "show-loading" : "hide-loading");
      }
    },
    async onBeneficiarySelection(value) {
      const alterarLoading = !this.isLoading;
      try {
        this.controleLoading(alterarLoading, true);
        if (value && value !== this.selectedBeneficiary) {
          this.selectedBeneficiary = value;
          if (alterarLoading) await this.iniciarCalendario();
        }
      } finally {
        this.controleLoading(alterarLoading, false);
      }
    },
    async onLocalSelection(value) {
      const alterarLoading = !this.isLoading;
      try {
        this.controleLoading(alterarLoading, true);
        if (value && value !== this.selectedLocal) {
          const idLocal = Number(value);
          const localObj = this.task.Locais.find(l => l.Id === idLocal);
          this.selectedLocal = value;
          this.selectedAddress = localObj?.Endereco;

          if (alterarLoading) await this.iniciarCalendario();
        }
      } finally {
        this.controleLoading(alterarLoading, false);
      }
    },
    async onStaffSelection(value) {
      const alterarLoading = !this.isLoading;
      try {
        this.controleLoading(alterarLoading, true);
        if (value && value !== this.selectedStaff) {
          this.selectedStaff = this.staffsDisponiveis?.find(s => s.value === value) ? value : "";
        }
        if (!this.selectedStaff && this.staffsDisponiveis?.length === 1) {
          this.selectedStaff = this.staffsDisponiveis[0].value;
        }
        window.scrollTo(0, document.body.scrollHeight);
      } finally {
        this.controleLoading(alterarLoading, false);
      }
    },
    async onDayClick(value) {
      const alterarLoading = !this.isLoading;
      try {
        this.controleLoading(alterarLoading, true);
        if (value?.date && value.date !== this.selectedDate) {
          this.diaAgenda = this.agenda?.find(d => d.Dia === value.day && d.Mes === value.month && d.Ano === value.year);
          this.selectedDate = value.date;
          this.selectedTime = "";
          if (this.diaAgenda) {
            this.horariosDisponiveis = this.diaAgenda.Horarios?.map(h => h.Hora) ?? [];
            this.calendarAttributes[0].dates = value.date;
            if (this.horariosDisponiveis.length === 1) {
              await this.onTimeClick(this.horariosDisponiveis[0]);
            }
          }
          window.scrollTo(0, document.body.scrollHeight);
        }
      } finally {
        this.controleLoading(alterarLoading, false);
      }
    },
    async onTimeClick(value) {
      const alterarLoading = !this.isLoading;
      try {
        this.controleLoading(alterarLoading, true);
        if (value && value !== this.selectedTime) {
          const currentStaff = this.selectedStaff;
          this.selectedTime = value;
          if (!this.task.StaffBloqueado) {
            this.selectedStaff = "";
            window.scrollTo(0, document.body.scrollHeight);
            this.$nextTick(() => this.onStaffSelection(currentStaff));
          }
        }
      } finally {
        this.controleLoading(alterarLoading, false);
      }
    },
    async iniciarCalendario() {
      const alterarLoading = !this.isLoading;
      try {
        this.controleLoading(alterarLoading, true);
        if (this.selectedBeneficiary && this.selectedLocal && this.selectedSpeciality) {
          this.agenda = null;
          this.selectedDate = "";
          this.selectedTime = "";
          this.diaAgenda = null;
          this.horariosDisponiveis = [];
          this.calendarAttributes[0].dates = null;
          if (!this.task.StaffBloqueado) {
            this.selectedStaff = "";
          }
          var agenda = await this.featchCalendar({
            beneficiario: this.selectedBeneficiary,
            local: this.selectedLocal,
            especialidade: this.selectedSpeciality,
            staff: this.task.StaffBloqueado ? this.selectedStaff : 0
          });
          this.task.Staffs = agenda?.Staffs ?? [];
          this.agenda = agenda?.DiasDisponiveis ?? [];
          window.scrollTo(0, document.body.scrollHeight);
        }
      } finally {
        this.controleLoading(alterarLoading, false);
      }
    },
    async onSubmit() {
      if (this.disableButton) return;
      try {
        await this.$root.$emit("show-loading");
        const horario = this.diaAgenda?.Horarios?.find(h => h.Hora === this.selectedTime);
        const staff = horario.Staffs.find(sh => sh.IdStaff === this.selectedStaff);

        const chamadoGerado = await this.createTask({
          beneficiario: this.selectedBeneficiary,
          local: this.selectedLocal,
          especialidade: this.selectedSpeciality,
          staff: this.selectedStaff,
          dia: this.diaAgenda.Dia,
          mes: this.diaAgenda.Mes,
          ano: this.diaAgenda.Ano,
          hora: this.selectedTime,
          duracao: null,
          chamado: null,
          reserva: this.task.Reserva,
          slot: staff.Slot,
          tipo: TIPO_OPERACAO
        });
        if (chamadoGerado.MensagemProcessamento) {
          await this.$root.$emit("hide-loading");
          Swal.fire({
            icon: "error",
            text: chamadoGerado.MensagemProcessamento,
            confirmButtonText: "OK"
          });
        } else {
          await this.$root.$emit("hide-loading");
          await Swal.fire({
            html: this.$refs.sucessMessage,
            confirmButtonText: "Continuar",
            showCloseButton: true
          });
          const rota = await this.checkNextWelcomeOnBoardTour();
          this.$router.replace(rota);
        }
      } catch (error) {
        await this.$root.$emit("hide-loading");
        Swal.fire({
          icon: "error",
          text: "Serviço temporariamente indisponível",
          confirmButtonText: "OK"
        });
        window.$log.error("Erro ao criar a tarefa", error);
      } finally {
        await this.$root.$emit("hide-loading");
      }
    },
    async iniciarRegras() {
      const task = await this.featchTaskRules({
        chamado: null,
        prestador: null,
        tipo: TIPO_OPERACAO
      });
      if (!task) return false;
      this.task = task;
      if (task.MensagemProcessamento) return false;
      if (!task.Beneficiarios?.length) return false;
      if (!task.Locais?.length) return false;
      if (!task.Especialidades?.length) return false;
      if (task.DiasDisponiveis?.length) {
        this.agenda = task.DiasDisponiveis;
        task.DiasDisponiveis = null;
      }
      if (task.Beneficiario) {
        this.selectedBeneficiary = task.Beneficiario;
      } else if (task.Beneficiarios.length === 1) {
        this.selectedBeneficiary = task.Beneficiarios[0].Codigo;
      }
      this.selectedSpeciality = task.Especialidades.find(e => e.Nome.toUpperCase().includes("ENFERM"))?.Id ?? "";
      return true;
    },
    async initPage() {
      let regrasValidas = false;
      try {
        regrasValidas = await this.iniciarRegras();
      } catch (error) {
        window.$log.error("Erro ao carregar a tarefa", error);
      } finally {
        if (regrasValidas) {
          await this.$root.$emit("hide-loading");
          this.isLoading = false;
        } else {
          this.$toast({
            description: this.task?.MensagemProcessamento ?? "Não foi possível obter as regras de agendamento",
            status: "error",
            duration: 10000,
            position: "top"
          });
          this.$router.replace("/welcome/equipe-saude/resume");
        }
      }
    }
  },
  async mounted() {
    await this.$root.$emit("show-loading");
    await this.initPage();
  }
};
</script>
<style>
.date-selected {
  color: #fff;
  border-radius: 3px !important;
  background-color: var(--colors-light-primary-navy-blue);
}
</style>
