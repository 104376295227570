































































































import { defineComponent } from "vue";
import RefundInvoiceAdviser from "../RefundInvoiceAdviser.vue";
import RefundAttentionAdviser from "../RefundAttentionAdviser.vue";
import IconRow from "@/components/Atoms/icon-row.vue";

export default defineComponent({
  components: {
    RefundInvoiceAdviser,
    RefundAttentionAdviser,
    IconRow
  }
});
