<template>
  <CBox>
    <OmtRow mb="8px">
      <OmtTypography as="h1-bold" lineHeight="32px">{{ title }}</OmtTypography>
    </OmtRow>
    <OmtRow mb="8px">
      <OmtTypography as="p1" lineHeight="22px">
        <span v-html="text"> </span>
      </OmtTypography>
    </OmtRow>
    <OmtRow mb="24px">
      <OmtInput
        variant="filled"
        iconRight="search"
        :placeholder="placeholder"
        @input="emitSearchValue"
        class="input-search-exames"
      />
    </OmtRow>
  </CBox>
</template>

<script>
export default {
  name: "ExamesSearch",
  props: {
    title: { type: String, required: true, default: "Buscar exames" },
    text: {
      type: String,
      required: false
    },
    placeholder: { type: String, required: false }
  },
  methods: {
    emitSearchValue(value) {
      this.$emit("search", value);
    }
  }
};
</script>

<style lang="scss" scoped>
.input-search-exames ::v-deep path {
  color: #4e4e4e;
}
</style>
