






















import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    protocol: {
      type: String,
      required: true
    },
    detailIcon: {
      type: String,
      required: true
    },
    detailTitle: {
      type: String,
      required: true
    },
    detailDescription: {
      type: String,
      required: true
    },
    actionLabel: {
      type: String,
      default: "Concluir"
    }
  },
  emits: ["close", "action"]
});
