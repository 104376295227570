import NotPay from "@/pages/inadimplencia/index.vue";

const routes: Route[] =
  process.env.NODE_ENV !== "production"
    ? [
        {
          path: "/inadimplencia",
          name: "notpay.index",
          component: NotPay,
          meta: {
            checkPathDisabled: true
          }
        }
      ]
    : [];

export default routes;
