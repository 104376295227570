












import { defineComponent, PropType } from "vue";

export default defineComponent({
  inheritAttrs: true,
  props: {
    p: {
      type: Object as PropType<String | Number>,
      required: false,
      default: "16px"
    },
    mt: {
      type: Object as PropType<String | Number>,
      required: false,
      default: "16px"
    },
    mb: {
      type: Object as PropType<String | Number>,
      required: false,
      default: "16px"
    }
  }
});
