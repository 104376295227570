<template>
  <OmtLayoutContainer>
    <resouce-modal v-if="shouldRequestResources" :useMic="false" />
    <OmtRow z-index="1">
      <RefundListFiltersHeader
        :isOpen.sync="showFiltersOptions"
        :filters="filters"
        :activedSort="activedSort"
        @change="SET_FILTERS($event)"
        @cleanSorts="SET_ACTIVED_SORT($event)"
        :filtersHasChanges.sync="filtersHasChanges"
      >
        <RefundListSortButton :sorts="sorts" @sort="SET_ACTIVED_SORT($event)" :initialValue="activedSort" />
      </RefundListFiltersHeader>
      <RefundListFilterModal
        :isOpen.sync="showFiltersOptions"
        :filters="filters"
        @save="SET_FILTERS($event)"
        :filtersHasChanges.sync="filtersHasChanges"
      />
    </OmtRow>

    <OmtRow mt="24px" v-for="(refund, index) in filteredRefunds" :key="`reemb-${index}`">
      <RefundListCard v-bind="refund" @click="showDetail(refund)" @evaluate="evaluateRequest(refund)" />
    </OmtRow>

    <FixedButtonRow>
      <OmtButton :w="['100%', '308px !important']" @click="createNewRefund" :disabled="!addPermission">
        Solicitar novo reembolso
      </OmtButton>
    </FixedButtonRow>
    <CBox h="30px" />

    <Onboard v-if="shouldOpenOnboard" onboard="refund-onboard" />

    <AvaliationModal
      v-if="showAvaliationModal"
      @close="showAvaliationModal = false"
      @ratingSubmitClick="onRatingSubmitClick($event)"
      :protocolNumber="avaliation.protocol"
      :protocolDate="avaliation.date"
      protocolTypeName="Reembolso"
      protocolType="3"
      stage="P"
    />
  </OmtLayoutContainer>
</template>

<script>
import RefundListCard from "@/components/Organisms/RefundNew/RefundListCard.vue";
import RefundListFiltersHeader from "@/components/Organisms/RefundNew/RefundListFiltersHeader.vue";
import RefundListFilterModal from "@/components/Organisms/RefundNew/RefundListFilterModal.vue";
import RefundListSortButton from "@/components/Organisms/RefundNew/RefundListSortButton.vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import Onboard, { onboards } from "@/components/Organisms/Onboard";
import checkBiometryRules from "@/components/Organisms/Biometry/checkRule.js";
import AvaliationModal from "@/components/Organisms/AvaliationModal.vue";
import ResouceModal from "@/components/Organisms/DrOmint/ResourceModal";
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";

export default {
  components: {
    ResouceModal,
    RefundListCard,
    RefundListFiltersHeader,
    RefundListFilterModal,
    RefundListSortButton,
    AvaliationModal,
    Onboard,
    FixedButtonRow
  },
  mixins: [checkBiometryRules],
  data() {
    return {
      avaliation: { date: "", protocol: "" },
      prevRoute: "",
      showAvaliationModal: false,
      showFiltersOptions: false,
      shouldOpenOnboard: false,
      filtersHasChanges: false
    };
  },
  computed: {
    ...mapGetters("saude", ["baseUrl", "onboardWasAlreadyClosed"]),
    ...mapGetters("refund", ["filteredRefunds"]),
    ...mapState("refund", ["protocols", "pendencies", "addPermission", "sorts", "filters", "activedSort"])
  },
  async created() {
    try {
      this.checkPermissionForResource("camera");

      const wasAlreadyClosed = await this.onboardWasAlreadyClosed("refund-onboard");
      const pendingOptin = await this.getOnboardOptin({ template: onboards["refund-onboard"].template });

      this.shouldOpenOnboard = !!pendingOptin.Texto && !wasAlreadyClosed;
    } catch (error) {
      console.error(error);
    }
  },
  beforeRouteEnter(_, from, next) {
    next(vm => {
      vm.prevRoute = from.path;
    });
  },
  async mounted() {
    try {
      await this.$root.$emit("show-loading");
      await Promise.all([this.fetchProtocols(), this.fetchAddPermission()]);
      this.initialFilter();
    } catch {
      // TODO confirmar se vai exibir algum aviso
    } finally {
      await this.$root.$emit("hide-loading");
    }

    // TODO FIX
    if ("newRefundStatus" in this.$route.params) {
      this.$toast({
        description: this.$route.params.newRefundStatus
          ? "Sua resposta foi enviada para análise"
          : "Algum erro aconteceu no envio",
        status: this.$route.params.newRefundStatus ? "success" : "error",
        duration: 10000,
        position: "top"
      });
    }
  },
  methods: {
    ...mapMutations("refund", ["SET_FILTERS", "SET_ACTIVED_SORT"]),
    ...mapActions("refund", ["fetchProtocols", "fetchAddPermission", "getDontShowReceiptDeliveryNoticeAgain"]),
    ...mapActions("biometrics", ["checkPermissionForResource"]),
    ...mapActions("saude", ["getOnboardOptin"]),
    evaluateRequest(request) {
      if (!request?.protocol || !request?.createdAt) return;
      this.avaliation = {
        protocol: request.protocol,
        date: request.createdAt
      };
      this.showAvaliationModal = true;
    },
    initialFilter() {
      if (!this.prevRoute || (!this.prevRoute.startsWith("/refund") && !this.prevRoute.startsWith("/reembolso"))) {
        // se não veio de uma navegação do reem
        this.SET_FILTERS(this.filters.map(f => ({ isActive: false, label: f.label })));
        this.SET_ACTIVED_SORT("");
      }
      if (!("status" in this.$route.params)) return;

      const statusFromRoute = this.$route.params.status.toUpperCase();
      for (var i = 0; i < this.protocols.length; i++) {
        var foundType = this.protocols[i].resumeDocTypes.find(rdt => rdt.type === statusFromRoute);

        if (foundType) {
          this.SET_FILTERS(this.filters.map(f => ({ isActive: f.label === foundType.description, label: f.label })));
          break;
        }
      }
      if (this.filteredRefunds.length === 1) {
        this.showDetail(this.filteredRefunds[0]);
      }
    },
    async onRatingSubmitClick(rating) {
      if (!rating?.newRatingValue || !this.avaliation?.protocol) return;
      try {
        this.$root.$emit("show-loading");
        await this.fetchProtocols({ protocol: this.avaliation.protocol });
      } catch (error) {
        console.error(error);
      } finally {
        this.avaliation = {
          protocol: "",
          date: ""
        };
        this.showAvaliationModal = false;
        this.$root.$emit("hide-loading");
      }
    },
    showDetail(refund) {
      if (!refund?.protocol) return;

      this.$router.push({
        name: "refund.new.detail",
        params: {
          protocolo: refund.protocol
        }
      });
    },
    async createNewRefund() {
      this.$root.$emit("show-loading");
      const response = await this.getDontShowReceiptDeliveryNoticeAgain();
      this.$root.$emit("hide-loading");
      let path = "";
      if (response) {
        path = "/refund-new/create";
      } else {
        path = "/refund-new/aviso-entrega-recibos-reembolso";
      }
      this.validateActiveBiometry({
        service: "/refund/form",
        rules: [],
        type: "",
        routeBack: path,
        sucessCallBack: _ => this.$router.push(path)
      });
    }
  }
};
</script>

<style scoped></style>
