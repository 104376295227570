











































import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    address: {
      type: Object as PropType<{
        CEP: string;
        Endereco: string;
        UF: string;
      }>,
      required: true
    },
    isSearching: {
      type: Boolean,
      required: false
    }
  }
});
