
























































import RadioGroupModal from "@/components/Molecules/RadioGroupModal/index.vue";
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";
import CoverageAnalysesItem, { CoverageAnalysesItemModel } from "@/components/Molecules/CoverageAnalysesItem/index.vue";
import CoverageAnalysesResult from "@/components/Molecules/CoverageAnalysesResult/index.vue";
import { defineComponent } from "vue";
import { mapMutations, mapState } from "vuex";

const items: CoverageAnalysesItemModel[] = [
  {
    date: new Date(Date.now()),
    hasPendency: false,
    exams: [
      {
        name: "Hemograma",
        state: "coverage"
      }
    ]
  },
  {
    date: new Date(Date.now()),
    hasPendency: true,
    exams: [
      {
        name: "Hemograma",
        state: "coverage"
      },
      {
        name: "Tomografia de crânio",
        state: "authorization"
      }
    ]
  },
  {
    date: new Date(Date.now()),
    hasPendency: true,
    exams: [
      {
        name: "Hemograma",
        state: "coverage"
      },
      {
        name: "Raio X do Torax",
        state: "request"
      }
    ]
  },
  {
    date: new Date(Date.now()),
    hasPendency: true,
    exams: [
      {
        name: "Hemograma",
        state: "coverage"
      },
      {
        name: "Tomografia de crânio",
        state: "authorization"
      },
      {
        name: "Raio X do Torax",
        state: "request"
      }
    ]
  },
  {
    date: new Date(Date.now()),
    hasPendency: false,
    exams: [
      {
        name: "Hemograma",
        state: "coverage"
      },
      {
        name: "Tomografia de crânio",
        state: "authorization"
      }
    ]
  },
  {
    date: new Date(Date.now()),
    hasPendency: false,
    exams: [
      {
        name: "Hemograma",
        state: "coverage"
      },
      {
        name: "Raio X do Torax",
        state: "request"
      }
    ]
  },
  {
    date: new Date(Date.now()),
    hasPendency: false,
    exams: [
      {
        name: "Hemograma",
        state: "coverage"
      },
      {
        name: "Tomografia de crânio",
        state: "authorization"
      },
      {
        name: "Raio X do Torax",
        state: "request"
      }
    ]
  }
];

export default defineComponent({
  components: {
    RadioGroupModal,
    FixedButtonRow,
    CoverageAnalysesItem,
    CoverageAnalysesResult
  },
  data() {
    return {
      showSortModal: false,
      activeSort: "",
      items: items
    };
  },
  mounted() {
    if (this.newAuthSuccess) {
      this.SET_SUCCESS_NEW_AUTH(false);
    }
  },
  computed: {
    ...mapState("auths", ["newAuthSuccess"])
  },
  methods: {
    ...mapMutations("auths", ["SET_SUCCESS_NEW_AUTH"]),
    handleClick() {
      this.$router.push({
        name: "analise_cobertura.nova_analise"
      });
    }
  }
});
