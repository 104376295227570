








import { defineComponent } from "vue";

export default defineComponent({
  async mounted() {
    await this.$root.$emit("hide-loading");
  },
  computed: {
    title() {
      switch (this.$route.name) {
        case "analise_cobertura.pedido":
        case "analise_cobertura.pedido_e_autorizacao":
          return "Pedido médico";
        default:
          return "Análise de coberturas";
      }
    }
  }
});
