<template>
  <omint-page>
    <Header name="ExternalLink" backRoute="/" :titleLabel="name" />
    <OmtGrid
      bg="transparent !important"
      height="79px"
      my="0 !important"
      py="16px !important"
      :px="['16px !important', '0 !important']"
      align-items="center"
      class="header-grid"
    >
      <OmtRow>
        <iframe
          id="externalLink"
          class="inner"
          :src="link"
          v-on:load="fileLoaded"
          frameborder="0"
          allowfullscreen
          cookieSameSite="None"
        ></iframe>
      </OmtRow>
    </OmtGrid>
  </omint-page>
</template>

<script>
import Header from "@/components/Molecules/Header";

export default {
  components: { Header },
  props: ["link", "name"],
  name: "ExternalLink",
  data() {
    return {
      loading: true,
      width: 0,
      height: 0,
      lastName: ""
    };
  },
  methods: {
    fileLoaded() {
      this.loading = false;
      this.$root.$emit("hide-loading");
    },
    onResizeHandler() {
      this.width = window.innerWidth;
      this.height = window.innerHeight - 70;
    },
    setLoading() {
      this.lastName = this.name;
      this.loading = true;
      this.$root.$emit("show-loading");
      this.onResizeHandler();
      setTimeout(this.fileLoaded, 5000);
    },
    onMessageHandler(event) {
      if (event.origin !== "https://pages.omint.com.br") return;

      switch (event.data) {
        case "back":
          this.$router.back();
          break;
        case "end":
          this.$router.replace("/");
          break;
        default:
          break;
      }
    }
  },
  created() {
    window.addEventListener("resize", this.onResizeHandler);
    window.addEventListener("message", this.onMessageHandler);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResizeHandler);
    window.removeEventListener("message", this.onMessageHandler);
  },
  mounted() {
    if (!this.link) {
      this.$router.back();
    }
    this.setLoading();
    this.$root.$emit("hide-back-button");
  },
  updated() {
    if (this.name !== this.lastName) {
      this.setLoading();
    }
  }
};
</script>
<style scoped>
iframe {
  width: 100%;
  height: calc(100vh + 2px);
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>
