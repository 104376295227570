
















































import { defineComponent } from "vue";
import EmphasysRow from "@/components/Atoms/emphasys-row.vue";
import IconRow from "@/components/Atoms/icon-row.vue";
import RefundInvoiceAdviser from "./RefundInvoiceAdviser.vue";
import RefundAttentionAdviser from "./RefundAttentionAdviser.vue";
import ConsultasPanel from "./topics/ConsultasPanel.vue";
import ExamesPanel from "./topics/ExamesPanel.vue";
import VacinasPanel from "./topics/VacinasPanel.vue";
import AcupunturaPanel from "./topics/AcupunturaPanel.vue";
import FisioterapiaPanel from "./topics/FisioterapiaPanel.vue";
import PanelTitle from "./topics/PanelTitle.vue";
import InternacoesPanel from "./topics/InternacoesPanel.vue";
import OdontologiaPanel from "./topics/OdontologiaPanel.vue";

/**
 * @prop {Boolean} isOpen
 * @Event {( Array<{ label: string, isActive: boolean }> ) => void} change
 */
export default defineComponent({
  components: {
    EmphasysRow,
    IconRow,
    RefundInvoiceAdviser,
    RefundAttentionAdviser,
    PanelTitle,
    ConsultasPanel,
    ExamesPanel,
    VacinasPanel,
    AcupunturaPanel,
    FisioterapiaPanel,
    InternacoesPanel,
    OdontologiaPanel
  },
  props: {
    isOpen: {
      type: Boolean
    }
  }
});
