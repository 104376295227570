<template>
  <OmtLayoutContainer>
    <OmtRow mb="16px">
      <OmtTypography as="h1-bold"> Selecione no máximo 4 laboratórios </OmtTypography>
    </OmtRow>

    <OmtRow mb="24px">
      <OmtInput
        variant="filled"
        iconRight="search"
        placeholder="Busque pelo nome do laboratório"
        class="input-search-laboratories"
        v-model="search"
        :iconRightClick="onSearch"
        v-on:keydown.enter.prevent="onSearch"
      />
    </OmtRow>

    <OmtRow stack v-if="address.infos" mb="16px">
      <SeeLabRulesPainel :is-searching="isSearching" :address="address.infos" />
    </OmtRow>

    <OmtRow mb="16px">
      <OmtTypography as="h2-bold"> Laboratórios </OmtTypography>
    </OmtRow>
    <OmtRow stack mb="16px" v-if="searchedLaboratories.length === 0">
      <CFlex>
        <OmtIcons name="circle-exclamation" size="1x" mr="8px" />
        <OmtTypography as="p1" color="var(--colors-light-primary-gray1)">
          Não localizamos laboratórios que atendam aos critérios acima.
        </OmtTypography>
      </CFlex>
    </OmtRow>

    <OmtRow class="full-view" stack>
      <template v-for="(lab, index) in searchedLaboratories">
        <OmtDivider v-if="index !== 0" w="calc(100% - 32px) !important" m="0 auto !important" />
        <LabItem
          :key="'lab-item_' + index"
          :logo="lab.logo"
          :name="lab.name"
          :address="lab.address"
          :distance="lab.distance"
          :is-disabled="!canSelectLab && !returnBoolean(lab)"
          :is-selected="returnBoolean(lab)"
          @selection="selectLaboratory(lab)"
        />
      </template>
    </OmtRow>

    <FixedButtonRow>
      <ButtonCounter
        :width="['', '30% !important']"
        :count="selectedLabsCount"
        :disabled="!selectedLabsCount"
        @click="submit"
        label="Gerar simulação"
      />
    </FixedButtonRow>
  </OmtLayoutContainer>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ButtonCounter from "@/components/Molecules/ButtonCounter/index.vue";
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";
import LabItem from "@/components/Molecules/LabItem/index.vue";
import SeeLabRulesPainel from "@/components/Molecules/SeeLabRulesPainel/index.vue";

export default {
  components: {
    ButtonCounter,
    FixedButtonRow,
    LabItem,
    SeeLabRulesPainel
  },
  data() {
    return {
      isSearching: false,
      search: ""
    };
  },
  async mounted() {
    if (!this.selectedExams.length) {
      this.$router.push("/copay/simulations/create/review");
      return;
    }
    try {
      this.$root.$emit("show-loading");
      await this.fetchBaseAddress({ type: "R" });
      await this.fetchMostSearchedLaboratories();
    } catch (error) {
      console.error("copay labs error", error);
    } finally {
      this.$root.$emit("hide-loading");
    }
  },
  computed: {
    ...mapState("copay", [
      "address",
      "selectedExams",
      "mostSearchedLaboratoriesTerms",
      "selectedLaboratories",
      "searchedLaboratories"
    ]),
    terms() {
      if (!this.mostSearchedLaboratoriesTerms?.length) return [];
      return this.mostSearchedLaboratoriesTerms.map(t => ({
        label: t,
        isActive: (t === "Todos" && !this.isSearching) || t === this.search
      }));
    },
    canSelectLab() {
      return this.selectedLaboratories.length < 4;
    },
    selectedLabsCount() {
      return this.selectedLaboratories?.length ?? 0;
    }
  },
  methods: {
    ...mapActions("copay", [
      "fetchMostSearchedLaboratories",
      "searchLaboratory",
      "triggerLaboratory",
      "fetchBaseAddress",
      "submitSimulation"
    ]),
    selectLaboratory(lab) {
      const isActive = !lab.isActive;
      this.triggerLaboratory({ isActive, lab });

      if (isActive) {
        this.$toast({
          description: "Laboratório selecionado com sucesso",
          status: "success",
          duration: 2000,
          position: "top"
        });
      }
    },
    async onSearch() {
      try {
        this.$root.$emit("show-loading");
        this.isSearching = this.search !== "";
        await this.searchLaboratory({ search: this.search });
      } catch (error) {
        console.error("copay exam error", error);
      } finally {
        this.$root.$emit("hide-loading");
      }
    },
    returnBoolean(lab) {
      return lab.isActive ?? false;
    },
    async submit() {
      try {
        this.$root.$emit("show-loading");
        const erroMsg = await this.submitSimulation();
        if (erroMsg) {
          this.$toast({
            description: erroMsg,
            status: "error",
            duration: 10000,
            position: "top"
          });
          return;
        }
        this.$root.$emit("finish-simulation");
        this.$router.push({ path: "/copay/simulations/view" });
        this.$root.$emit("hide-back-button");
      } catch (error) {
        console.error(error);
      } finally {
        this.$root.$emit("hide-loading");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.full-view {
  margin-left: -16px;
  min-width: calc(100% + 32px);
}

.input-search-laboratories ::v-deep input {
  font-weight: 400;
}

.input-search-laboratories ::v-deep svg {
  font-size: 16px;
}

@media screen and (min-width: 1024px) {
  .full-view {
    margin-left: -36px;
    min-width: calc(100% + 72px);
  }
}

.full-view-swiper {
  ::v-deep .swiper {
    padding: 0 16px;
  }

  ::v-deep .swiper-slide {
    width: auto;
  }

  button {
    pointer-events: none;
  }
}
</style>
