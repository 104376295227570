





























































import OmtTextSignature from "@/components/Atoms/omt-text-signature.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    OmtTextSignature
  }
});
