<template>
  <OmtLayoutContainer>
    <OmtRow mb="24px">
      <OmtTypography as="h1-bold"> Modelo de coparticipação </OmtTypography>
    </OmtRow>

    <OmtRow mb="24px">
      <OmtTypography as="p1"> Confira o modelo de coparticipação do seu plano: </OmtTypography>
    </OmtRow>

    <OmtRow gap="24px" stack>
      <OmtCard v-for="(item, index) in models" :key="index">
        <CFlex justify-content="space-between" align-items="center" mb="16px">
          <CFlex>
            <OmtIcons :name="item.icon" size="lg" mr="16px" />
            <OmtTypography as="h3-bold">{{ item.title }}</OmtTypography>
          </CFlex>
          <CBox @click="item.isOpen = !item.isOpen" cursor="pointer">
            <OmtIcons :name="item.isOpen ? 'chevron-up' : 'chevron-down'" />
          </CBox>
        </CFlex>
        <OmtDivider mb="0 !important" />
        <CCollapse :is-open="item.isOpen">
          <CGrid template-columns="repeat(5, 1fr)" my="16px">
            <OmtTypography color="var(--colors-light-primary-gray2)" as="p2" text-align="right" grid-column-start="4">
              Percentual
            </OmtTypography>
            <OmtTypography color="var(--colors-light-primary-gray2)" as="p2" text-align="right"> Limite </OmtTypography>
          </CGrid>

          <CGrid template-columns="repeat(5, 1fr)" v-for="(model, id) in item.data" :key="id">
            <OmtDivider v-if="id !== 0" grid-column-start="1" grid-column-end="6" />
            <OmtTypography as="p1" grid-column-start="1" grid-column-end="4">
              {{ model.label }}
            </OmtTypography>
            <OmtTypography as="h3-bold" grid-column-start="4" text-align="right" grid-column-end="5">
              {{ model.value }}
            </OmtTypography>
            <OmtTypography as="h3-bold" grid-column-start="5" text-align="right" grid-column-end="6">
              {{ model.limit }}
            </OmtTypography>
          </CGrid>
        </CCollapse>
      </OmtCard>
    </OmtRow>
  </OmtLayoutContainer>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      models: []
    };
  },
  computed: {
    ...mapState("copay", ["copayModel"])
  },
  methods: {
    ...mapActions("copay", ["fetchCopayModel"]),
    mapModels() {
      this.models = (this.copayModel ?? []).map(m => ({
        title: m.title,
        icon: m.icon,
        isOpen: true,
        data: (m.values ?? []).map(v => ({
          label: v.label ?? "",
          value: v.value,
          limit: v.limit
        }))
      }));
    }
  },
  async mounted() {
    try {
      this.$root.$emit("show-loading");
      await this.fetchCopayModel();
      this.mapModels();
    } finally {
      this.$root.$emit("hide-loading");
    }
  }
};
</script>
