const hasUpperCase = (value: string) => {
  const UpperCaseChars = /[A-Z]/;
  return UpperCaseChars.test(value);
};
const hasNumber = (value: string) => {
  const numbersChars = /[0-9]/;
  return numbersChars.test(value);
};

const hasSpecialChars = (value: string) => {
  const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  return specialChars.test(value);
};

const isValidFullName = value => {
  const fullNamePattern = /^[aA-zZzáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\s]+$/;

  return fullNamePattern.test(value);
};

const isValidDate = (value: string) => {
  const datePattern = /^(\d{2})\/(\d{2})\/(\d{4})$/;
  if (!datePattern.test(value)) {
    return false;
  }

  const [_, day, month, year] = value.match(datePattern);
  const parsedYear = parseInt(year, 10);
  const parsedMonth = parseInt(month, 10);
  const parsedDay = parseInt(day, 10);

  if (parsedYear < 1900 || parsedYear > 2100) {
    return false;
  }

  if (parsedMonth < 1 || parsedMonth > 12) {
    return false;
  }

  const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate();
  if (parsedDay < 1 || parsedDay > daysInMonth) {
    return false;
  }

  return true;
};

export default {
  hasUpperCase,
  hasNumber,
  hasSpecialChars,
  isValidDate,
  isValidFullName
};
