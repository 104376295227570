import RefundNew from "@/pages/refund-new/index.vue";
import RefundNewList from "@/pages/refund-new/list.vue";
import RefundNewDetail from "@/pages/refund-new/detail.vue";
import RefundNewCreate from "@/pages/refund-new/create/index.vue";
import RefundNewCreateType from "@/pages/refund-new/create/type.vue";
import RefundNewCreateTerms from "@/pages/refund-new/create/terms.vue";
import RefundNewCreateCreditInform from "@/pages/refund-new/create/credit-inform.vue";
import RefundNewCreatePicturesUpload from "@/pages/refund-new/create/pictures-upload.vue";
import RefundNewCreateComments from "@/pages/refund-new/create/comments.vue";
import RefundNewAudit from "@/pages/refund-new/audit/index.vue";
import RefundNewAuditExplanation from "@/pages/refund-new/audit/explanation.vue";
import RefundNewAuditTerm from "@/pages/refund-new/audit/term.vue";
import AuthMiddleware from "@/pages/auth-middleware/index.vue";
import ReceiptDeliveryNotice from "@/pages/refund-new/receipt-delivery-notice.vue";

const routes: Route[] = [
  {
    path: "/refund-new/aviso-entrega-recibos-reembolso",
    component: ReceiptDeliveryNotice,
    meta: {
      backTo: "/refund-new",
      validPath: ["/refund-new"]
    }
  },
  {
    path: "/refund-new",
    component: RefundNew,
    children: [
      {
        path: "detail/:protocolo",
        name: "refund.new.detail",
        component: RefundNewDetail,
        meta: {
          backTo: "/refund-new",
          validPath: ["/refund-new"]
        }
      },
      {
        path: "create",
        component: RefundNewCreate,
        meta: {
          backTo: "/refund-new",
          registerAccess: { servico: "Reembolso", funcionalidade: "Acesso a solicitação de reembolso" },
          validPath: ["/refund-new"]
        },
        children: [
          {
            path: "",
            name: "refund.new.create",
            component: RefundNewCreateType,
            meta: {
              backTo: "/refund-new",
              routerSequence: 0,
              validPath: ["/refund-new"]
            }
          },
          {
            path: "terms",
            name: "refund.new.create.terms",
            component: RefundNewCreateTerms,
            meta: {
              backTo: "/refund-new/create",
              routerSequence: 1,
              validPath: ["/refund-new"]
            }
          },
          {
            path: "credit-inform",
            name: "refund.new.create.credit-inform",
            component: RefundNewCreateCreditInform,
            meta: {
              backTo: "/refund-new/create/terms",
              routerSequence: 2,
              validPath: ["/refund-new"]
            }
          },
          {
            path: "pictures-upload",
            name: "refund.new.create.pictures-upload",
            component: RefundNewCreatePicturesUpload,
            meta: {
              backTo: "/refund-new/create/credit-inform",
              routerSequence: 3,
              validPath: ["/refund-new"]
            }
          },
          {
            path: "comments",
            component: AuthMiddleware,
            children: [
              {
                path: "",
                name: "refund.new.create.comments",
                component: RefundNewCreateComments,
                meta: {
                  auth_service: "/refund/form",
                  auth_get_type: "refund/getNewRefundType",
                  backTo: "/refund-new/create/pictures-upload",
                  routerSequence: 4,
                  save_auth_mutation: "refund/SET_AUTHORIZATION_ID",
                  validPath: ["/refund-new"]
                }
              }
            ]
          }
        ]
      },
      {
        path: "audit-explanation",
        name: "refund.new.audit.explanation",
        component: RefundNewAuditExplanation,
        meta: {
          validPath: ["/refund-new"]
        }
      },
      {
        path: "audit",
        component: AuthMiddleware,
        children: [
          {
            path: "",
            name: "refund.new.audit",
            component: RefundNewAudit,
            meta: {
              auth_service: "/refund/audit",
              save_auth_mutation: "refund/SET_AUTHORIZATION_ID",
              validPath: ["/refund-new"]
            }
          },
          {
            path: "audit/term",
            name: "refund.new.audit.term",
            component: RefundNewAuditTerm,
            meta: {
              auth_service: "/refund/audit",
              save_auth_mutation: "refund/SET_AUTHORIZATION_ID",
              validPath: ["/refund-new"]
            }
          }
        ]
      },
      {
        path: ":status?",
        component: RefundNewList,
        name: "refund.new",
        meta: {
          backTo: "/",
          registerAccess: { servico: "Reembolso", funcionalidade: "Acesso a listagem de reembolsos" },
          validPath: ["/refund-new"]
        }
      }
    ]
  },
  {
    path: "/aviso-entrega-recibos-reembolso",
    component: ReceiptDeliveryNotice,
    props: true,
    meta: {
      basePath: "/",
      validPath: ["/"]
    }
  }
];

export default routes;
