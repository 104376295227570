<template>
  <OmtStickedModal v-if="isOpen" @close="close" data-cy="refund-create-terms-modal">
    <OmtRow>
      <OmtTypography as="h1-bold"> Evite atrasos na liberação do seu reembolso </OmtTypography>
    </OmtRow>
    <OmtRow mt="16px">
      <OmtTypography as="p1">
        Na próxima etapa, verifique os documentos e informações necessárias para cada tipo de reembolso.
      </OmtTypography>
    </OmtRow>
    <OmtRow mt="16px">
      <OmtTypography as="p1">
        <strong>Lembre-se:</strong> os documentos fiscais aceitos por este canal são: Recibos digitais emitidos conforme
        a IN nº 2.240/24 e Notas Fiscais Eletrônicas.
      </OmtTypography>
    </OmtRow>
    <template #footer>
      <OmtButton w="100%" @click="save"> Entendi </OmtButton>
    </template>
  </OmtStickedModal>
</template>

<script>
/**
 * @prop {Boolean} isOpen
 */
export default {
  props: {
    isOpen: {
      Type: Boolean
    }
  },
  methods: {
    close() {
      this.$emit("update:isOpen", false);
    },
    save() {
      this.$emit("continue");
      this.close();
    }
  }
};
</script>
