import Coverage from "@/pages/coverage-analysis/index.vue";
import CoverageHome from "@/pages/coverage-analysis/home.vue";
import CoverageRequest from "@/pages/coverage-analysis/coverage-request/index.vue";
import CoverageNewAnalysis from "@/pages/coverage-analysis/new-analysis/index.vue";
import SeeLabs from "@/pages/coverage-analysis/see-labs/index.vue";

const routes: Route[] =
  process.env.NODE_ENV !== "production"
    ? [
        {
          path: "/analise-cobertura",
          name: "analise_cobertura",
          component: Coverage,
          children: [
            {
              path: "",
              name: "analise_cobertura.cobertura",
              component: CoverageHome,
              meta: {
                backTo: "/",
                routerSequence: 0,
                validPath: ["/"]
              }
            },
            {
              path: "pedido",
              name: "analise_cobertura.pedido",
              component: CoverageRequest,
              meta: {
                backTo: "/",
                routerSequence: 1,
                validPath: ["/", "/analise-cobertura"]
              }
            },
            {
              path: "pedido-e-autorizacao",
              name: "analise_cobertura.pedido_e_autorizacao",
              component: CoverageRequest,
              meta: {
                backTo: "/",
                routerSequence: 1,
                validPath: ["/", "/analise-cobertura"]
              }
            },
            {
              path: "ver-laboratorios",
              name: "analise_cobertura.ver_laboratorios",
              component: SeeLabs,
              meta: {
                backTo: "/",
                routerSequence: 2,
                validPath: ["/", "/analise-cobertura"]
              }
            },
            {
              path: "nova-analise",
              name: "analise_cobertura.nova_analise",
              component: CoverageNewAnalysis,
              meta: {
                backTo: "/analise-cobertura/",
                routerSequence: 2,
                validPath: ["/", "/analise-cobertura"]
              }
            }
          ]
        }
      ]
    : [];

export default routes;
