<template>
  <CFlex direction="column">
    <CFlex v-for="(file, idx) in myFilesTutorial" :key="`sec-${idx}`" direction="column">
      <omt-divider v-if="idx !== 0" />
      <OmtTypography as="h2-bold" mb="22px">
        {{ file.name }}
      </OmtTypography>
      <CBox
        v-for="(f, i) in file.itemCollection"
        :key="`files-${idx}-${i}`"
        cursor="pointer"
        w="100%"
        @click.prevent="openFile(f.Url)"
      >
        <OmtTypography as="p0" mb="16px" color="var(--colors-light-primary-navy-blue)" text-decoration="underline">{{
          f.Name
        }}</OmtTypography>
      </CBox>
    </CFlex>

    <FixedButtonRow :useFooter="false" :solidColor="true">
      <OmtButton @click.prevent="onNextClick" :w="['100%', '308px !important']" variant="solid">
        <OmtTypography as="h1-bold" :color="'white'"> Concluir </OmtTypography>
      </OmtButton>
    </FixedButtonRow>
  </CFlex>
</template>

<script>
import { mapState, mapActions } from "vuex";
import FixedButtonRow from "@/components/Atoms/FixedButtonRow/index.vue";

export default {
  components: { FixedButtonRow },
  computed: {
    ...mapState("myFiles", ["myFiles"]),
    myFilesTutorial() {
      if (!this.myFiles?.length) return [];

      for (const file of this.myFiles) {
        file.itemCollection = file.itemCollection.sort((a, b) => a.Name.localeCompare(b.Name));
      }
      return this.myFiles.sort((a, b) => a.name.localeCompare(b.name));
    }
  },
  methods: {
    ...mapActions("myAccount", ["checkNextWelcomeOnBoardTour"]),
    ...mapActions("myFiles", ["listFiles"]),
    async onNextClick() {
      const rota = await this.checkNextWelcomeOnBoardTour();
      this.$router.replace(rota);
    },
    openFile(url) {
      window.open(url, "_blank");
    }
  },
  async mounted() {
    try {
      await this.$root.$emit("show-loading");
      await this.listFiles();
    } finally {
      if (!this.myFiles || this.myFiles.length === 0) {
        this.error = "Nenhum documento encontrado";
      }
      await this.$root.$emit("hide-loading");
    }
  }
};
</script>
<style scoped></style>
