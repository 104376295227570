








import EmphasysRow from "@/components/Atoms/emphasys-row.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    EmphasysRow
  },
  props: {
    itens: {
      type: String,
      default: "4, 5 e 6"
    }
  }
});
