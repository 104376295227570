import { render, staticRenderFns } from "./observation.vue?vue&type=template&id=af3b0ca8&scoped=true&"
import script from "./observation.vue?vue&type=script&lang=js&"
export * from "./observation.vue?vue&type=script&lang=js&"
import style0 from "./observation.vue?vue&type=style&index=0&id=af3b0ca8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af3b0ca8",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex, CBox: require('@chakra-ui/vue').CBox})
