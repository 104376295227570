<template>
  <OmtGrid :minHeight="['auto', 'calc(100vh - 210px)']">
    <OmtRow :min-height="['calc(100vh - 266px)', 'calc(100vh - 400px)']">
      <CFlex flex-direction="column">
        <OmtTypography as="h1-bold" mb="24px"> Solicitar novo reembolso </OmtTypography>
        <OmtTypography mb="16px" as="p1">
          Esta solicitação de reembolso inclui despesas de <b>exames</b> ou <b>terapias de qualquer tipo</b>?
        </OmtTypography>
        <CFlex mb="24px" justify-content="center">
          <OmtButton
            height="42px"
            :max-width="['100%', '290px']"
            :variant="refundType === true ? 'solid' : 'outline'"
            flex="1"
            border-color="var(--colors-light-secondary-divider)"
            mr="16px"
            @click="refundType = true"
          >
            <OmtTypography
              :color="refundType === true ? 'white' : 'var(--colors-light-primary-gray1)'"
              :as="['h3-bold', 'h2-bold']"
            >
              Sim
            </OmtTypography>
          </OmtButton>
          <OmtButton
            height="42px"
            :max-width="['100%', '290px']"
            :variant="refundType === false ? 'solid' : 'outline'"
            flex="1"
            border-color="var(--colors-light-secondary-divider)"
            mr="0"
            @click="refundType = false"
          >
            <OmtTypography
              :color="refundType === false ? 'white' : 'var(--colors-light-primary-gray1)'"
              :as="['h3-bold', 'h2-bold']"
            >
              Não
            </OmtTypography>
          </OmtButton>
        </CFlex>
      </CFlex>
    </OmtRow>
    <OmtRow :desktopStart="4" :desktopEnd="9">
      <CStack :align="['flex-start', 'center']">
        <OmtButton w="100%" mb="24px" @click="submit" :disabled="refundType === null">Continuar</OmtButton>
      </CStack>
    </OmtRow>
  </OmtGrid>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {
      refundType: null
    };
  },
  async mounted() {
    try {
      await this.$root.$emit("show-loading");
      if (!this.addPermission) {
        await this.fetchAddPermission();
      }
      if (!this.addPermission) {
        this.$router.push({ name: "refund.new" });
        return;
      }
      if ("type" in (this.newRefund ?? [])) {
        this.refundType = this.newRefund.type;
      }
    } finally {
      await this.$root.$emit("hide-loading");
    }
  },
  methods: {
    ...mapActions("refund", ["fetchAddPermission"]),
    ...mapMutations("refund", ["SET_NEW_REFUND"]),
    async submit() {
      await this.$root.$emit("show-loading");
      this.SET_NEW_REFUND({ type: this.refundType });
      this.$router.push({
        name: "refund.new.create.terms"
      });
    }
  },
  computed: {
    ...mapState("refund", ["addPermission", "newRefund"])
  }
};
</script>
