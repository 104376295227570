<template>
  <OmtCard cursor="pointer" display="flex" justify-content="space-between" v-bind="$attrs" v-on="$listeners">
    <OmtTile
      :divider="false"
      :hasArrow="false"
      :icon="icon.name"
      :iconSize="icon.size"
      flex-direction="column"
      gap="0"
      alignItems="flex-start"
    >
      <OmtTypography v-if="htmlTitle" as="h3-bold" v-html="title" :style="{ marginBottom: titleMarginBottom }" />
      <OmtTypography v-else as="h3-bold" :style="{ marginBottom: titleMarginBottom }"> {{ title }}</OmtTypography>

      <template v-slot:lead>
        <OmtTypography
          v-if="!!description"
          as="p2"
          ml="30px"
          v-html="description"
          color="var(--colors-light-primary-gray2)"
        >
          {{ description }}
        </OmtTypography>
      </template>
    </OmtTile>
    <slot name="lead" />
  </OmtCard>
</template>

<script>
export default {
  props: {
    icon: {
      type: Object,
      default: () => ({
        name: "plus",
        size: "md"
      })
    },
    title: String,
    description: String,
    htmlTitle: Boolean,
    htmlDescription: Boolean,
    titleMarginBottom: {
      type: String,
      default: "0px"
    }
  }
};
</script>
